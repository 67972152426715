import {createSlice, createAsyncThunk, current} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";

const initialState = {
    searchCriteria: undefined,
    selectedId: [],
    currentPage: 1,
    pageSize: 20,
    order: {by: 'created', direction: 'DESC'},
    loading: false,
    totalRecords: 0,
    reload: false,
    alertMessage: undefined,
    inputs: []
};

export const getInputs = createAsyncThunk('inputs/get-all', async (arg, {getState, requestId}) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/managed-inputs`;
    const inputs = await Api.get(true, GET_SOURCE_URL, buildQuery(getState().inputs.currentPage, getState().inputs.pageSize,
        getState().inputs.order.by, getState().inputs.order.direction, getState().inputs.searchCriteria));
    return inputs;
});

export const getInputsWithFilter = createAsyncThunk('inputs/get-all-with-filter', async (filter) => {
    const GET_SOURCE_URL = `${config.services.ATLAS.apiUrl}/managed-inputs`;
    const inputs = await Api.get(true, GET_SOURCE_URL, filter);
    return inputs;
});

export const getInputsCount = createAsyncThunk('inputs/getInputCount', async (arg, {getState, requestId}) => {
    // calls to getState().* when error are just silently swallowed. Handle errors properly
    try {
        const SOURCE_COUNT_URL = `${config.services.ATLAS.apiUrl}/managed-inputs/count`;
        const inputsCount = await Api.get(true, SOURCE_COUNT_URL, buildQuery(getState().inputs.currentPage, getState().inputs.pageSize,
            getState().inputs.order.by, getState().inputs.order.direction, getState().inputs.searchCriteria));
        return inputsCount?.count;
    } catch (err) {
        console.error(err);
    }
});


const buildQuery = (currentPage, pageSize, orderBy, orderDirection, searchCriteria) => {
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const isGuid = regexExp.test(searchCriteria);
    const query = isGuid ? {
        filter: {
            // fields: ['id', 'sourceId', 'type', 'status', 'name', 'label', 'description',
            //     'url', 'qualityScore', 'authoritative', 'public', 'products', 'indicators',
            //     'created'],
            where: {
                or: [{
                    id: {
                        eq: `${searchCriteria ? searchCriteria : ''}`
                    }
                },
                    {
                        sourceId: {
                            eq: `${searchCriteria ? searchCriteria : ''}`
                        }
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    } : {
        filter: {
            // fields: ['id', 'sourceId', 'type', 'status', 'name', 'label', 'description',
            //     'url', 'qualityScore', 'authoritative', 'public', 'products', 'indicators',
            //     'created'],
            where: {
                and: [
                    {
                        or: [{
                            name: {
                                ilike: `%${searchCriteria ? searchCriteria : ''}%`
                            }
                        },
                            {
                                description: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            },
                            {
                                label: {
                                    ilike: `%${searchCriteria ? searchCriteria : ''}%`
                                }
                            }
                        ]
                    }
                ]
            },
            order: [`${orderBy} ${orderDirection}`],
            limit: pageSize,
            skip: (currentPage - 1) * pageSize
        },
    };
    return query;
}


const inputsSlice = createSlice({
    name: 'inputsSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
            }
        },
        setOrder: {
            reducer(state, action) {
                state.order.by = _.get(action.payload, 'by');
                state.order.direction = _.get(action.payload, 'direction');
            }
        },
        setSelectedId: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedId.indexOf(action.payload.id) >= 0) {
                        state.selectedId.splice(state.selectedId.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedId.push(action.payload.id);
                    }
                }
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        }
    },
    extraReducers: {
        [getInputs.pending]: (state, action) => {
            state.loading = true;
        },
        [getInputs.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.inputs = action.payload;
            }
        },
        [getInputs.rejected]: (state, action) => {
            state.loading = false;
        },
        [getInputsCount.pending]: (state, action) => {
            state.loading = true;
        },
        [getInputsCount.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.totalRecords = action.payload;
            }
        },
        [getInputsCount.rejected]: (state, action) => {
            state.loading = false;
        },
        [getInputsWithFilter.pending]: (state, action) => {
            state.loading = true;
        },
        [getInputsWithFilter.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.inputs = action.payload;
            }
        },
        [getInputsWithFilter.rejected]: (state, action) => {
            state.loading = false;
        }
    }
})


export const {setCurrentPage, setOrder, setSelectedId, clearSelected, setSearchCriteria} = inputsSlice.actions;
export default inputsSlice.reducer;

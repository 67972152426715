import CheckBox from '../../common/components/CheckBox';
import { FormLabel } from 'react-bootstrap';
import { FormGroup } from 'react-bootstrap';
import FormSelect from '../../common/components/form/FormSelect';
import React, {Component, useEffect, useState} from 'react';
import FormNumericInput from '../../common/components/FormNumericInput';
import {useDispatch, useSelector} from "react-redux";
import {Row, Col} from 'react-bootstrap';
import FormTextInput from "../../common/components/FormTextInput";
import SectionTogglePanel from "../../organization/explore/components/SectionTogglePanel";
import {getSuppliers}  from "../suppliers/supplierSlice";

export default function OrganizationConfigForm(props) {
    const {
        setConfig,
        defaultConfig,
        formValid
    } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(getSuppliers());
    }, []);

    // Loading meta data
    const metaDataState = useSelector(state => state.metaData);
    const suppliersState = useSelector(state => state.suppliers);

    const regionList = [];
    metaDataState['companyAddressRegion'].forEach(element => {
        regionList.push({ value: element.id, label: element.display })
    });
    const defaultRegions = defaultConfig.config.subscription.geographies.countries.us.regions;
    const tempRegions = [];
    (defaultRegions ?? []).forEach(element => {
        if (regionList.map(a => a.value).includes(element)) {
            tempRegions.push(regionList.find(a => a.value === element));
        }
    });

    const msaList = [];
    metaDataState['companyAddressLocMsa'].forEach(element => {
        msaList.push({ value: element.id, label: element.display })
    });
    const defaultMsa = defaultConfig.config.subscription.geographies.countries.us.msas;
    const tempMsa = [];
    (defaultMsa ?? []).forEach(element => {
        if (msaList.map(a => a.value).includes(element)) {
            tempMsa.push(msaList.find(a => a.value === element));
        }
    });

    const classificationList = [];
    metaDataState['companyClassTrades'].forEach(element => {
        classificationList.push({ value: element.id, label: element.display })
    });
    const defaultClassifications = defaultConfig.config.subscription.products.taxonomy.classifications;
    const tempClassifications = [];
    if (defaultClassifications !== null) {
        (defaultClassifications.allowed ?? []).forEach(element => {
            if (classificationList.map(a => a.value).includes(element)) {
                tempClassifications.push(classificationList.find(a => a.value === element));
            }
        });
    }

    const featuresList = [{ value: 'search', label: 'Search' }, { value: 'enrich', label: 'Enrich' }, { value: 'export', label: 'Export' }, { value: 'chat', label: 'Chat' }];
    const defaultFeatures = defaultConfig.config.features;
    const tempFeatures = [];
    (defaultFeatures ?? []).forEach(element => {
        if (featuresList.map(a => a.value).includes(element)) {
            tempFeatures.push(featuresList.find(a => a.value === element));
        }
    });

    let supplierCount = 0;
    const supplierList = suppliersState.suppliers?.map((sup) => {
        return {value: sup.id, label: sup.label};
    });
    supplierCount = supplierList?.length;
     const tempSuppliers = [];
     useEffect(() => {
         // When open dialog, we clear state.
        const defaultSuppliers = defaultConfig.config.suppliers;
        (defaultSuppliers ?? []).forEach(element => {
            if (supplierList.map(a => a.value).includes(element)) {
                tempSuppliers.push(supplierList.find(a => a.value === element));
            }
        });
        setSuppliers(tempSuppliers);
    }, [supplierCount]);

    // Set state variables
    const [companyScore, setCompanyScore] = useState(defaultConfig.config.scores.companyScore);
    const [contactScore, setContactScore] = useState(defaultConfig.config.scores.contactScore);
    const [scoresAllowUnfiltered, setScoresAllowUnfiltered] = useState(defaultConfig.config.scores.allowUnfiltered);
    const [features, setFeatures] = useState(tempFeatures);
    const [suppliers, setSuppliers] = useState(tempSuppliers);
    const [segments, setSegments] = useState(defaultConfig.config.subscription.products.taxonomy.segments);
    const [classifications, setClassifications] = useState(tempClassifications);
    const [services, setServices] = useState(defaultConfig.config.subscription.products.taxonomy.services);
    const [taxonomiesUnrestricted, setTaxonomiesUnrestricted] = useState(defaultConfig.config.subscription.products.taxonomy.unrestricted);
    const [cities, setCities] = useState(defaultConfig.config.subscription.geographies.countries.us.cities);
    const [msa, setMsa] = useState(tempMsa);
    const [regions, setRegions] = useState(tempRegions);
    const [geographiesUnrestricted, setGeographiesUnrestricted] = useState(defaultConfig.config.subscription.geographies.countries.us.unrestricted);

    const getValueFromObj = (obj) => {
        // Gets an array of value fields from an array of objects
        let result = obj.map(a => a.value);
        return result;
    }

    const emptyArrToNull = (obj) => {
        if ((obj ?? []).length === 0) {
            return null;
        }
        return obj;
    }

    useEffect(() => {
        const getClassifications = () => {
            if (taxonomiesUnrestricted) {
                return null;
            }

            return ({
                allowed: emptyArrToNull(getValueFromObj(classifications ?? [])),
                filterOn: 'trades',
                allowUnclassified: (getValueFromObj(classifications ?? []).length === 0)
            });
        }

        const getCities = () => {
            if ((cities ?? '') === '') {
                return [];
            }

            if (Array.isArray(cities)) {
                return (cities ?? '').map(s => s.trim());
            }
            return (cities ?? '').split(',').map(s => s.trim());
        }

        const newSegments = emptyArrToNull(taxonomiesUnrestricted ? null : segments);
        const newServices = emptyArrToNull(taxonomiesUnrestricted ? null : services);
        const newClassifications = (taxonomiesUnrestricted ? null : getClassifications());

        const newTaxonomyUnrestricted = (taxonomiesUnrestricted || (newSegments == null && newServices == null && newClassifications.allowed == null));

        const newMsa = emptyArrToNull(geographiesUnrestricted ? null : getValueFromObj(msa ?? []));
        const newCities = emptyArrToNull(geographiesUnrestricted ? null : getCities());
        const newRegions = emptyArrToNull(geographiesUnrestricted ? null : getValueFromObj(regions ?? []));

        const newConfig = {
            "scores":{
              "companyScore":companyScore,
              "contactScore":contactScore,
              "allowUnfiltered": scoresAllowUnfiltered
            },
            "features": getValueFromObj(features ?? []),
            "suppliers": getValueFromObj(suppliers ?? []),
            "subscription":{
              "products":{
                "taxonomy":{
                  "unrestricted":newTaxonomyUnrestricted,
                  "segments": newSegments,
                  "services": newServices,
                  "classifications": (newTaxonomyUnrestricted ? null : newClassifications)
                },
                "subscribed":[
                  "business_universe",
                  "contractor_universe"
                ]
              },
              "geographies":{
                "countries":{
                  "us":{
                    "msas": newMsa,
                    "cities": newCities,
                    "regions": newRegions,
                    "unrestricted":(geographiesUnrestricted || (newMsa == null && newCities == null && newRegions == null))
                  }
                }
              }
            }
        };

        setConfig(newConfig);
    }, [cities, classifications, companyScore, contactScore, features, suppliers, geographiesUnrestricted, msa, regions, scoresAllowUnfiltered, segments, services, setConfig, taxonomiesUnrestricted]);

    return (
        <div>
            <FormLabel className={"formTextLabel"}>Config</FormLabel>
            <hr />

            <SectionTogglePanel
                title={'SUBSCRIPTIONS'}
                isExpand={true}
                overflowX={false}
            >
                <Row>
                    <FormGroup as={Col} controlId='formGridCompanyScore' id="formGridCompanyScore">
                        <FormNumericInput
                            label={'Company Score'}
                            placeholder="Enter company score"
                            value={companyScore}
                            onChange={value => setCompanyScore(value)}
                            max={0.9}
                            min={0.6}
                            isInvalid={!formValid && !(companyScore >= 0.6 && companyScore <= 0.9)}
                            size={'sm'}
                            required
                        />
                    </FormGroup>
                    <FormGroup as={Col} controlId='formGridContactScore' id="formGridContactScore">
                        <FormNumericInput
                            label={'Contact Score'}
                            placeholder="Enter contact score"
                            value={contactScore}
                            onChange={value => setContactScore(value)}
                            max={0.9}
                            min={0.6}
                            isInvalid={!formValid && !(contactScore >= 0.6 && contactScore <= 0.9)}
                            size={'sm'}
                            required
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <Col md={9}>
                        <div id="scores-checkbox-div">
                            <CheckBox
                                label={'Scores - Allow Unfiltered'}
                                checked={scoresAllowUnfiltered}
                                onChange={value => setScoresAllowUnfiltered(value)}
                                isInvalid={!formValid && !(scoresAllowUnfiltered === true || scoresAllowUnfiltered === false)}
                                size={'sm'}
                            />
                        </div>
                    </Col>
                </Row>
                <div id="features-div">
                    <FormSelect
                        label={'Features'}
                        placeholder="Enter features"
                        value={features}
                        onChange={value => setFeatures(value)}
                        options={featuresList}
                        isMulti={true}
                        size={'sm'}
                        required
                    />
                </div>
                <FormLabel className={"formTextLabel"} style={{ marginTop: '12px' }}>Taxonomies</FormLabel>
                <div style={{ border: '0.5px ridge', padding: '12px' }}>
                    <div id="segments-div">
                        <FormSelect
                            label={'Segments'}
                            placeholder="Enter segments"
                            value={segments}
                            onChange={value => setSegments(value)}
                            isMulti={true}
                            size={'sm'}
                            disabled={true}
                        />
                    </div>
                    <div id="classifications-div">
                        <FormSelect
                            label={'Classifications'}
                            placeholder="Enter classifications"
                            value={classifications}
                            onChange={value => setClassifications(value)}
                            options={classificationList}
                            isMulti={true}
                            size={'sm'}
                            disabled={taxonomiesUnrestricted}
                        />
                    </div>
                    <div id="services-div">
                        <FormSelect
                            label={'Services'}
                            placeholder="Enter services"
                            value={services}
                            onChange={value => setServices(value)}
                            isMulti={true}
                            size={'sm'}
                            disabled={true}
                        />
                    </div>
                    <div id="taxonomies-unrestricted-check-div">
                        <CheckBox
                            id={"taxonomies-unrestricted-check"}
                            label={'Unrestricted'}
                            checked={taxonomiesUnrestricted}
                            onChange={value => setTaxonomiesUnrestricted(value)}
                            isInvalid={!formValid && !(taxonomiesUnrestricted === true || taxonomiesUnrestricted === false)}
                            size={'sm'}
                        />
                    </div>
                </div>
                <FormLabel className={"formTextLabel"} style={{ marginTop: '12px' }}>Geographies</FormLabel>
                <div style={{ border: '0.5px ridge', padding: '12px' }}>
                    <div id="cities-div">
                        <FormTextInput
                            label={'Cities'}
                            placeholder="Enter cities (comma separated)"
                            value={cities}
                            onChange={value => setCities(value)}
                            isInvalid={!formValid && !cities}
                            size={'sm'}
                            disabled={geographiesUnrestricted}
                        />
                    </div>
                    <div id="msa-div">
                        <FormSelect
                            label={'MSA'}
                            placeholder="Enter MSA"
                            value={msa}
                            onChange={value => setMsa(value)}
                            options={msaList}
                            isMulti={true}
                            size={'sm'}
                            disabled={geographiesUnrestricted}
                        />
                    </div>
                    <div id="regions-div">
                        <FormSelect
                            label={'Regions'}
                            placeholder="Enter regions"
                            value={regions}
                            onChange={value => setRegions(value)}
                            options={regionList}
                            isMulti={true}
                            size={'sm'}
                            disabled={geographiesUnrestricted}
                        />
                    </div>
                    <div id="geographies-unrestricted-check-div">
                        <CheckBox
                            id={'geographies-unrestricted-check'}
                            label={'Unrestricted'}
                            checked={geographiesUnrestricted}
                            onChange={value => setGeographiesUnrestricted(value)}
                            isInvalid={!formValid && !(geographiesUnrestricted === true || geographiesUnrestricted === false)}
                            size={'sm'}
                        />
                    </div>
                </div>
            </SectionTogglePanel>
            <SectionTogglePanel
                title={'EXPLORE'}
                isExpand={true}
                overflowX={false}
            >
                <div id="features-div" >
                    <FormSelect
                        label={'Suppliers'}
                        placeholder="Enter Suppliers"
                        value={suppliers}
                        onChange={value => setSuppliers(value)}
                        options={supplierList}
                        isMulti={true}
                        size={'sm'}
                    />
                </div>
            </SectionTogglePanel>
        </div>
    );
}

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";

import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import FormJsonInput from "../../common/components/form/FormJsonInput";

import Alert from "react-bootstrap/Alert";
import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";

import { getCompanyById } from "./companySlice";

/**
 * Company Json View
 */
const CompanyJsonView = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const companyState = useSelector(state => state.adminCompany);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    const [adminCompany, setAdminCompany] = useState(null);

    useEffect(() => {
        if (props.match.params.id) {
            dispatch(getCompanyById({id: props.match.params.id}));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (companyState.company) {
            setAdminCompany(companyState.company);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Companies',
                        href: '/admin/e/companies',
                        isActive: false
                    },
                    {
                        label: `${companyState?.company['CompanyContractorUniverse.company_id']}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: 'View as JSON',
                        href: '',
                        isActive: true
                    }
                ]
            )
        }
    }, [companyState.company]);

    const handleCancel = async () => {
        history.push('/admin/e/companies')
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={`View as JSON - ${adminCompany && adminCompany['CompanyContractorUniverse.company_name'] || ''}`}
                subHeader={'View company as JSON'}
            />
            <Panel>
                {(companyState.loading || !adminCompany) && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => setShowAlert(!showAlert)}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
                <Alert
                    variant={"success"}
                    show={showSuccess}
                    onClose={() => setShowSuccess(!showSuccess)}
                    dismissible
                    transition={false}
                >
                    {successMessage}
                </Alert>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="viewAsJSON">
                            <Row>
                                <Col md={9}>
                                    <FormJsonInput
                                        id={'view-as-json'}
                                        label={'JSON'}
                                        placeholder={adminCompany}
                                        onChange={(v) => console.log(v)}
                                        height={'500px'}
                                        width={'100%'}
                                        viewOnly={true}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => {
                                            handleCancel().then();
                                        }}
                                    >DONE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                            handleCancel().then();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default CompanyJsonView;

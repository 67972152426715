/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from "react";
import styled from "styled-components";

import {
    faHouse,
    faBuilding,
    faWarehouse,
    faPersonShelter,
    faCalculator,
    faIdBadge,
    faSatelliteDish,
    faUser,
    faFile,
    faCertificate
} from "@fortawesome/free-solid-svg-icons";
import {
    faChimney,
    faFileCertificate, faTree,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {APP_COLORS} from "../../../common/Constants";

const BoxContainer = styled.div `
  display: grid;
  //grid-template-rows: 25px minmax(25px,max-content);
  padding: 0px 10px 0px 0px;
  height: auto;  
`;

const PillRow = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: center;
`;

const PillContainer = styled.div `
  border-radius: 5px;
  border: 1px solid ${APP_COLORS['gray-card']};
  margin: 3px;
  background-color: ${APP_COLORS['gray-100']};
  min-width: 180px;
`;

const PillText = styled.span `
  font-size: 14px;
  padding: 5px;
  // padding-left: 5px;
  // padding-right: 5px;
  color: ${APP_COLORS['tech-blue']};
  font-weight: 600;
`;

export default function AttributesBox(props) {
    const {
        item,
        showMore = false
    } = props;

    const pillIcons = [
        {attr: 'Arborist', value: false, icon: faTree, text: 'Arborist'},
        {attr: 'companyIndResidential', value: false, icon: faHouse, text: 'Residential'},
        {attr: 'Residential', value: false, icon: faHouse, text: 'Residential'},
        {attr: 'companyIndCommercial', value: false, icon: faBuilding, text: 'Commercial'},
        {attr: 'Commercial', value: false, icon: faBuilding, text: 'Commercial'},
        {attr: 'companyIndIndustrial', value: false, icon: faWarehouse, text: 'Industrial'},
        {attr: 'Industrial', value: false, icon: faWarehouse, text: 'Industrial'},
        {attr: 'companyIndExterior', value: false, icon: faChimney, text: 'Exterior'},
        {attr: 'Exterior', value: false, icon: faChimney, text: 'Exterior'},
        {attr: 'companyIndInterior', value: false, icon: faPersonShelter, text: 'Interior'},
        {attr: 'Interior', value: false, icon: faPersonShelter, text: 'Interior'},
        {attr: 'companyIndLicensed', value: false, icon: faFileCertificate, text: 'Licensed'},
        {attr: 'Licensed', value: false, icon: faFileCertificate, text: 'Licensed'},
        {attr: 'companyIndBonded', value: false, icon: faFileCertificate, text: 'Bonded'},
        {attr: 'Bonded', value: false, icon: faFileCertificate, text: 'Bonded'},
        {attr: 'companyIndInsured', value: false, icon: faFileCertificate, text: 'Insured'},
        {attr: 'Insured', value: false, icon: faFileCertificate, text: 'Insured'},
        {attr: 'companyProfileCnt', value: true, icon: faSatelliteDish, text: 'Profiles'},
        {attr: 'companyManufacturerCnt', value: true, icon: faWarehouse, text: 'Manufacturers'},
        {attr: 'companyAssociationCnt', value: true, icon: faCertificate, text: 'Associations'},
        {attr: 'companyLicenseCnt', value: true, icon: faIdBadge, text: 'Licenses'},
        {attr: 'companyPermitCnt', value: true, icon: faCalculator, text: 'Permits'},
        {attr: 'companyPolicyCnt', value: true, icon: faFileCertificate, text: 'Policies'},
        {attr: 'companyContactCnt', value: true, icon: faUser, text: 'Contacts'},
        {attr: 'companySourceCnt', value: true, icon: faFile, text: 'Sources'},
    ];

    const pills = [];

    if (Array.isArray(item)) {
        item.forEach((info, i) => {
            pills.push(
                <PillContainer key={i}>
                    <PillText>
                        <FontAwesomeIcon icon={faBuilding}
                        />&nbsp;{info}
                    </PillText>
                </PillContainer>
            )
        })
    } else {
        pillIcons.forEach((p, i) => {
            if (item[p.attr]) {
                if (p.value && item[p.attr]) {
                    pills.push(
                        <PillContainer key={i}>
                            <PillText>
                                <FontAwesomeIcon
                                    icon={p.icon}
                                />&nbsp;{item[p.attr]} {p.text}
                            </PillText>
                        </PillContainer>
                    )
                } else {
                    pills.push(
                        <PillContainer key={i}>
                            <PillText>
                                <FontAwesomeIcon
                                    icon={p.icon}
                                />&nbsp;{p.text}
                            </PillText>
                        </PillContainer>
                    )
                }
            }
        })
    }

    // Interpreted Values
    if (item.companyRegLocalRegistrationStatus === 'Active') {
        pills.push(
            // we should'nt be hardcoding the key ....
            <PillContainer key={99}>
                <PillText>
                    <FontAwesomeIcon
                        icon={faFileCertificate}
                    />&nbsp;Registered
                </PillText>
            </PillContainer>
        )
    }

    return (
        <BoxContainer>
            <PillRow>
                {pills.length > 0 && (
                    <>
                        { showMore ? (
                            <>
                                { pills }
                            </>
                        ) : (
                            <>
                                { pills.slice(0, 6) }
                            </>
                        )}
                    </>
                )}
            </PillRow>
        </BoxContainer>
    )
}

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assign, findIndex } from "lodash";
import { useHistory } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faExternalLink } from "@fortawesome/pro-solid-svg-icons";

import Alert from "react-bootstrap/Alert";

import {
    countCompanyByCriteria,
    searchCompanyProfileByCriteria,
    setSearchCriteria,
    setCurrentPage,
    setSelectedIds, clearSelectedIds
} from "./companiesSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import FormTextInput from "../../common/components/FormTextInput";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import TableWithPaging from "../../common/components/TableWithPaging";
import TextHoverDisplay from "../../common/components/TextHoverDisplay";
import CheckBox from "../../common/components/CheckBox";
import DropdownAction from "../../common/components/DropdownAction";
import TextWrap, { TextSpan } from "../../common/components/TextWrap";

import Container from "../../common/components/layout/Container";
import PanelTable from "../../common/components/layout/PanelTable";

import { APP_COLORS } from "../../common/Constants";

import btIcon from '../../common/assets/img/bt_logo_icon.png';

const Companies = (props) => {
    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Companies',
        href: '',
        isActive: true
    }];

    const history = useHistory();
    const dispatch = useDispatch();
    const companiesState = useSelector(state => state.adminCompanies);

    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    }

    const getActionItems = () => {
        const actionItems = [{
            label: 'VIEW AS JSON',
            href: '',
            isActive: companiesState?.selectedIds.length === 1,
            onClickHandler: () => history.push(`/admin/e/companies/${companiesState.selectedIds[0]}/view-as-json`)
        }];

        return actionItems;
    };

    const handleOnKeyDown = (target) => {
        if (target.code === 'Enter') {
            setLastReload(Date.now());
        }
    }

    useEffect(() => {
        dispatch(countCompanyByCriteria());
        dispatch(searchCompanyProfileByCriteria());
        dispatch(clearSelectedIds());
    }, [lastReload, dispatch]);

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            <PanelHeader
                header={`Companies (${companiesState.totalCompanies.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH</Button>
                        <DropdownAction items={getActionItems()}></DropdownAction>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            disabled={companiesState?.selectedIds.length !== 1}
                            onClick={() => history.push(`/admin/e/companies/${companiesState.selectedIds[0]}`)}
                        >VIEW</Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by id, name, identity...'}
                        value={companiesState.searchCriteria}
                        onKeyDown={handleOnKeyDown}
                        onChange={(value) => {
                            dispatch(setSearchCriteria(value));
                        }}
                        clearable
                        clearableHandle={() => {
                            dispatch(setSearchCriteria(''));
                            setLastReload(Date.now());
                        }}
                    />
                }
            />
            <PanelTable>
                {(companiesState.loading || companiesState.countLoading) && (
                    <SpinnerOverlay />
                )}
                <Alert
                    variant={"danger"}
                    show={showAlert}
                    onClose={() => {
                        setShowAlert(!showAlert);
                        setAlertMessage('');
                    }}
                    dismissible
                    transition={false}
                >
                    {alertMessage}
                </Alert>
              <CompaniesTable lastReload={lastReload} />
            </PanelTable>
        </Container>
    )
}

export default Companies;

const CompaniesTable = (props) => {
    const dispatch = useDispatch();
    const companiesState = useSelector(state => state.adminCompanies);

    const createHeader = (row) => {
        return [
            {
                id: 1,
                name: '',
                property: 'id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const id = row['CompanyContractorUniverse.company_id'];

                    return (
                        <div>
                            <CheckBox
                                innerState
                                onChange={value =>
                                    dispatch(setSelectedIds({
                                        id: id,
                                        isChecked: value,
                                        // statusId: row.statusId
                                    }))
                                }
                            />
                        </div>
                    )
                }
            },
            {
                id: 2,
                name: 'Id',
                property: 'CompanyContractorUniverse.company_id',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const companyId = row['CompanyContractorUniverse.company_id'];
                    if (companyId) {
                        return <TextHoverDisplay
                            truncatedText={companyId.substring(0, 13)}
                            fullText={companyId}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 3,
                name: 'Identity',
                property: 'companyIdentity',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    // TODO format the UUID and hover.
                    const companyIdentity = row['CompanyContractorUniverse.company_identity'];
                    if (companyIdentity) {
                        return <TextHoverDisplay
                            truncatedText={companyIdentity.substring(0, 13)}
                            fullText={companyIdentity}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 4,
                name: 'Score',
                property: 'companyScore',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row['CompanyContractorUniverse.company_score']) {
                        const companyScore = row['CompanyContractorUniverse.company_score'];
                        return <TextWrap propertyvalue={companyScore}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 5,
                name: '',
                property: 'companyName',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const isBT = row['CompanyContractorUniverse.company_builder_is_bt_builder']
                    if (isBT) {
                        return <Image
                            src={btIcon}
                            alt="Is BT Icon"
                            height={18}
                        />
                    }
                    return <span></span>
                }
            },
            {
                id: 6,
                name: 'Name',
                property: 'companyName',
                sortable: true,
                formatted: true,
                formatBy: (row) => {
                    if (row['CompanyContractorUniverse.company_name']) {
                        const companyName = row['CompanyContractorUniverse.company_name'];
                        return  <TextWrap propertyvalue={companyName}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 7,
                name: 'URL',
                property: 'companyUrl',
                sortable: false,
                formatted: true,
                formatBy: (row, header) => {
                    if (row['CompanyContractorUniverse.company_url']) {
                        const url = row['CompanyContractorUniverse.company_url'];
                        const regex = new RegExp('^(http|https)://');
                        return (
                            <a target='_blank' href={`${regex.test(url) ? url : 'https://' + url }`} rel='noopener noreferrer'>
                                <FontAwesomeIcon
                                    color={APP_COLORS['blue']}
                                    icon={faExternalLink}
                                    style={{marginRight: '5px', fontSize: '12px'}}
                                />
                                {`${url.replace(/(^\w+:|^)\/\//, '')}`}
                            </a>
                        )
                    }
                }
            },
            {
                id: 8,
                name: 'Address',
                property: 'companyAddressDeliveryLine1',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const address = row['CompanyContractorUniverse.company_address_delivery_line_1'];
                    const city = row['CompanyContractorUniverse.company_address_city'];
                    const region = row['CompanyContractorUniverse.company_address_region'];
                    const postalCode = row['CompanyContractorUniverse.company_address_postal_code'];

                    if (address || city || postalCode) {
                        const companyAddress = address + ' ' + city + ', ' + region + ' ' + postalCode;
                        return  <TextWrap propertyvalue={companyAddress}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 9,
                name: 'Phone',
                property: 'companyPhone',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row['CompanyContractorUniverse.company_phone']) {
                        const companyPhone = row['CompanyContractorUniverse.company_phone'];
                        return  <TextWrap propertyvalue={companyPhone}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 10,
                name: 'Email',
                property: 'companyEmail',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row['CompanyContractorUniverse.company_email']) {
                        const companyEmail = row['CompanyContractorUniverse.company_email'];
                        return  <TextWrap propertyvalue={companyEmail}></TextWrap>
                    }
                    return <span></span>
                }
            },
            {
                id: 11,
                name: 'Trades',
                property: 'companyClassTrades',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const primaryTrade = row['CompanyContractorUniverse.company_class_primary_trade'];
                    const trades = row['CompanyContractorUniverse.company_class_trades'];
                    const id = row['CompanyContractorUniverse.company_id']

                    if (trades) {
                        const trade = trades.split(',');

                        return (
                            <>
                                { trade.map((item, index) => (
                                    <TextSpan key={'companies-trades-' + id + '-' + index}>
                                        <span style={item === primaryTrade ? { color: APP_COLORS['orange'], fontWeight: 'bold' } : {}}>
                                            {item}
                                        </span>
                                        <>{(index+1) !==  trade.length && <span>,</span>}</>
                                    </TextSpan>
                                ))}
                            </>
                        )
                    }
                    return <span></span>
                }
            },
            {
                id: 12,
                name: 'Products',
                property: 'products',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    const businessUniverse = row['CompanyContractorUniverse.company_product_business_universe'];
                    const contractorUniverse = row['CompanyContractorUniverse.company_product_contractor_universe'];
                    const builderUniverse = row['CompanyContractorUniverse.company_product_builder_universe'];

                    const products = [];

                    if (businessUniverse) {
                        products.push('Business Universe');
                    }

                    if (contractorUniverse) {
                        products.push('Contractor Universe');
                    }

                    if (builderUniverse) {
                        products.push('Builder Universe');
                    }

                    if (products.length > 0) {
                        return  <TextSpan>{products.join(', ')}</TextSpan>
                    }
                    return <span></span>
                }
            },
            {
                id: 13,
                name: 'Contacts',
                property: 'companyStatEmployeesCnt',
                sortable: false,
                formatted: true,
                formatBy: (row) => {
                    if (row['CompanyContractorUniverse.company_stat_employees_cnt']) {
                        const employees = row['CompanyContractorUniverse.company_stat_employees_cnt'];
                        return  <TextWrap propertyvalue={employees}></TextWrap>
                    }
                    return <span></span>
                }
            },
        ]
    }

    const headersUtil = (hds, hd, sortOrder) => {
        const _headers = hds.map(hc => assign(hc, {sortOrder: null}));
        _headers.splice(
            findIndex(hds, {id: hd.id}),
            1,
            assign({}, hd, {
                sortOrder: sortOrder,
            })
        );
        return _headers;
    };

    const handleSort = (header) => {
        if (!header.sortable) return;

        let _sortOrder;

        if (!header.sortOrder) _sortOrder = 'ASC';
        if (header.sortOrder === 'ASC') _sortOrder = 'DESC';
        if (header.sortOrder === 'DESC') _sortOrder = 'ASC';

        setHeader(headersUtil(headers, header, _sortOrder));
        // dispatch(setOrder({ by: header.property, direction: _sortOrder }));
        onSelectPage(1, {
            [header.property]: _sortOrder.toLowerCase()
        });
    };

    const onSelectPage = (page) => {
        dispatch(setCurrentPage(page));
        dispatch(searchCompanyProfileByCriteria());
    };

    const [headers, setHeader] = useState(
        createHeader().map(hc => assign(hc, {
            sortOrder: null,
            handleSort: value => handleSort(value),
        }))
    );

    return (
        <div>
            <TableWithPaging
                id={'companies-table-with-paging'}
                headers={headers}
                rows={companiesState.companies}
                countOfResults={companiesState.totalCompanies}
                resultsPerPage={companiesState.pageSize}
                currentPage={companiesState.currentPage}
                onSelectPage={(page) => onSelectPage(page)}
            />
        </div>
    )
}

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {
    Redirect,
    Route,
    Switch
} from 'react-router-dom';
import {getMetaData} from '../common/metadata/metaDataSlice';

import AdminHomeRouter from './home/AdminHomeRouter.jsx';
import SourcesRouter from './sources/SourcesRouter.jsx';
import InputsRouter from './inputs/InputsRouter.jsx';
import OrganizationsRouter from "./organizations/OrganizationsRouter.jsx";
import AccountsRouter from "./accounts/AccountsRouter.jsx";
import AdminListsRouter from "./lists/AdminListsRouter";
import ManagedListErrorsRouter from "./list-errors/ManagedListErrorsRouter";
import ManagedListMatchesRouter from "./list-matches/ManagedListMatchesRouter";
import ManagedListMatchLogsRouter from "./list-match-logs/ManagedListMatchLogsRouter";
import ManagedMatchTemplatesRouter from "./match-templates/ManagedMatchTemplatesRouter";
import ManagedMatchTypesRouter from "./match-types/ManagedMatchTypesRouter";
import ContractorDirectoryRouter from "./contractor-directory/ContractorDirectoryRouter";
import ManagedIntegrationsRouter from "./integrations/ManagedIntegrationsRouter";
import EntityVerificationsRouter from "./entity-verifications/EntityVerificationsRouter";
import CompaniesRouter from "./companies/CompaniesRouter";

export default function AdminRouter() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMetaData());
    }, [dispatch]);

    return (
        <Switch>
            <Route path="/admin/home" exact component={AdminHomeRouter}/>
            <Route path="/admin/sources" component={SourcesRouter}/>
            <Route path="/admin/inputs" component={InputsRouter}/>
            <Route path="/admin/accounts" component={AccountsRouter} />
            <Route path="/admin/integrations" component={ManagedIntegrationsRouter} />
            <Route path="/admin/lists" component={AdminListsRouter} />
            <Route path="/admin/list-errors" component={ManagedListErrorsRouter} />
            <Route path="/admin/list-matches" component={ManagedListMatchesRouter} />
            <Route path="/admin/list-match-logs" component={ManagedListMatchLogsRouter} />
            <Route path="/admin/organizations" component={OrganizationsRouter} />
            <Route path="/admin/tools/contractor-directory" component={ContractorDirectoryRouter} />
            <Route path="/admin/match-templates" component={ManagedMatchTemplatesRouter} />
            <Route path="/admin/match-types" component={ManagedMatchTypesRouter} />
            <Route path="/admin/entity-verifications" component={EntityVerificationsRouter} />
            <Route path="/admin/e/companies" component={CompaniesRouter} />
            <Redirect to="/admin/home"/>
        </Switch>
    );
}

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as CubeApi from "../../common/CubeApi";

import config from "../../config";
// import * as Api from "../../common/Api";

const initialState = {
    loading: false,
    reload: false,
    alertMessage: undefined,
    successMessage: undefined,
    company: null
};

// const getProductSchema = async () => {
//     const apiUrl = `${config.services.ATLAS.apiUrl}/managed-product-schema-properties`;
//     return Api.get(true, `${apiUrl}`);
// };

export const getCompanyById = createAsyncThunk('admin-company/getCompanyById', async (id) => {
    console.log('[Get] Company id [' + id.id + ']');

    const meta = await CubeApi.meta(config.services.ATLAS.cubeBaseUrl);
    const companyContractorUniverseMeta = meta.cubes.find(cube => cube.name === 'CompanyContractorUniverse');
    const companyDimensions = companyContractorUniverseMeta.dimensions.map(dimension => String(dimension.name));

    // const dimensions = await getProductSchema();
    // const mapDimensions = dimensions.map(d => 'CompanyContractorUniverse.'+ d.property);
    //
    // console.log('META: ', companyDimensions);

    const _filters = [{
        member: "CompanyContractorUniverse.company_id",
        operator: "equals",
        values: [ id.id ]
    }];

    let payload = {
        dimensions: companyDimensions,
        timeDimensions: [],
        ungrouped: true,
        filters: _filters,
        limit: 1,
    };

    const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );

    return resultSet && resultSet.length > 0 && resultSet[0];
});

const companySlice = createSlice({
    name: 'adminCompany', initialState,
    reducers: {
        clearState: (state) => {
            state.loading = false;
            state.reload = false;
            state.alertMessage = undefined;
            state.successMessage = undefined;
            state.managedInput = null;
            return state;
        }
    },
    extraReducers: {
        [getCompanyById.pending]: (state, action) => {
            state.loading = true;
            state.successMessage = undefined;
            state.alertMessage = undefined;
        },
        [getCompanyById.fulfilled]: (state, action) => {
            state.loading = false;
            if (action.payload) {
                state.company = action.payload;
            }
        },
        [getCompanyById.rejected]: (state, action) => {
            state.loading = false;
            state.alertMessage = 'Unable to retrieve an input information';
        },
    }
});

export const {
    clearState
} = companySlice.actions;

export default companySlice.reducer;

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as CubeApi from "../../common/CubeApi";

import config from "../../config";

const initialState = {
    companies: [],
    selectedIds: [],
    totalCompanies: 0,
    currentPage: 1,
    pageSize: 20,
    order: {by: 'companyScore', direction: 'DESC'},
    orderBy: {},
    countLoading: false,
    loading: false,
    reload: false,
    searchCriteria: undefined,
};

export const countCompanyByCriteria = createAsyncThunk('admin-companies/count-by-criteria', async (arg, { getState }) => {
    console.log(`[count] querying [Company Profile], building query`);
    const criteria = getState().adminCompanies.searchCriteria;

    const _filters = [{
        or: [{
            member: "CompanyContractorUniverse.company_name",
            operator: "contains",
            values: [ String(criteria) ]
        },
        {
            member: "CompanyContractorUniverse.company_id",
            operator: "equals",
            values: [ criteria ]
        },
        {
            member: "CompanyContractorUniverse.company_identity",
            operator: "equals",
            values: [ criteria ]
        }]
    }];

    let countPayload = {
        measures: ['CompanyContractorUniverse.count_companies'],
        timeDimensions: [],
    };

    if (criteria && criteria !== '') {
        countPayload = {
            ...countPayload,
            filters: _filters,
        }
    }

    const countResult = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        countPayload
    );
    const countCompanies = countResult[0]['CompanyContractorUniverse.count_companies'] || 0;

    return {
        countCompanies
    };
});

export const searchCompanyProfileByCriteria = createAsyncThunk('admin-companies/find-by-criteria', async (arg, {getState, requestId}) => {
    console.log(`[search] querying in [Company Profile], building query`);

    const currentPage = getState().adminCompanies.currentPage;
    const pageSize = getState().adminCompanies.pageSize;

    const criteria = getState().adminCompanies.searchCriteria;

    const _filters = [{
        or: [{
            member: "CompanyContractorUniverse.company_name",
            operator: "contains",
            values: [ String(criteria) ]
        },
        {
            member: "CompanyContractorUniverse.company_id",
            operator: "equals",
            values: [ criteria ]
        },
        {
            member: "CompanyContractorUniverse.company_identity",
            operator: "equals",
            values: [ criteria ]
        }]
    }];

    const defaultOrder = {
      'CompanyContractorUniverse.company_score': 'desc',
      'CompanyContractorUniverse.company_class_primary_trade_score': 'desc'
    };
    let payload = {
        dimensions: [
            'CompanyContractorUniverse.company_id',
            'CompanyContractorUniverse.company_identity',
            'CompanyContractorUniverse.company_builder_is_bt_builder',
            'CompanyContractorUniverse.company_score',
            'CompanyContractorUniverse.company_name',
            'CompanyContractorUniverse.company_url',
            'CompanyContractorUniverse.company_address_delivery_line_1',
            'CompanyContractorUniverse.company_address_city',
            'CompanyContractorUniverse.company_address_region',
            'CompanyContractorUniverse.company_address_postal_code',
            'CompanyContractorUniverse.company_phone',
            'CompanyContractorUniverse.company_email',
            'CompanyContractorUniverse.company_class_primary_trade',
            'CompanyContractorUniverse.company_class_trades',
            'CompanyContractorUniverse.company_stat_employees_cnt',
            'CompanyContractorUniverse.company_product_business_universe',
            'CompanyContractorUniverse.company_product_contractor_universe',
            'CompanyContractorUniverse.company_product_builder_universe',
        ],
        timeDimensions: [],
        ungrouped: true,
        // filters: criteria && criteria !== '' && _filters,
        order: defaultOrder,
        limit: pageSize,
        offset: (currentPage - 1) * pageSize
    };

    if (criteria && criteria !== '') {
        payload = {
            ...payload,
            filters: _filters,
        }
    }

    console.log(`[search] query built identified filters, executing`);
    const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );

    console.log('[search] query executed');
    return resultSet
});

const companiesSlice = createSlice({
    name: 'adminCompanies', initialState,
    reducers: {
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload;
                // state.orderBy = action.payload.orderBy;
            }
        },
        setSearchCriteria: {
            reducer(state, action) {
                state.searchCriteria = action.payload;
                state.currentPage = 1;
            }
        },
        setSelectedIds: {
            reducer(state, action) {
                if (action?.payload?.id) {
                    if (state.selectedIds.indexOf(action.payload.id) >= 0) {
                        state.selectedIds.splice(state.selectedIds.indexOf(action.payload.id), 1);
                    }
                    if (action.payload.isChecked === true) {
                        state.selectedIds.push(action.payload.id);
                    }
                }
            }
        },
        clearSelectedIds: {
            reducer(state, action) {
                state.selectedIds = [];
            }
        },
    },
    extraReducers: {
        [searchCompanyProfileByCriteria.pending]: (state, action) => {
            state.loading = true;
            // state.currentPage = 1;
        },
        [searchCompanyProfileByCriteria.fulfilled]: (state, action) => {
            state.companies = action.payload;
            state.loading = false;
        },
        [searchCompanyProfileByCriteria.rejected]: (state, action) => {
            state.loading = false;
        },
        [countCompanyByCriteria.pending]: (state, action) => {
            state.countLoading = true;
        },
        [countCompanyByCriteria.fulfilled]: (state, action) => {
            state.totalCompanies = Number(action.payload.countCompanies);
            state.countLoading = false;
        },
        [countCompanyByCriteria.rejected]: (state, action) => {
            state.countLoading = false;
        },
  }
});

export const {
    setCurrentPage,
    setSearchCriteria,
    setSelectedIds,
    clearSelectedIds
} = companiesSlice.actions;

export default companiesSlice.reducer;

/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "./EntityVerifications.scss";
import {TableFooter} from "../../common/components/TableWithPaging";

import React, {
    useCallback,
    useMemo,
    useRef,
    useEffect,
    useState,
    StrictMode,
} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import {Button, Pagination} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    sortBy,
} from 'lodash';

import FormTextInput from "../../common/components/FormTextInput";

import {
    clearSelected,
    setSearchCriteria,
    getEntityVerificationsCount,
    getDenormCompanies,
    getDenormByCompanyId
} from "./entityVerificationsSlice";

import BreadCrumb from "../../common/components/BreadCrumb";
import DropdownAction from "../../common/components/DropdownAction";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";

import Container from "../../common/components/layout/Container";
import {AgGridReact} from "ag-grid-react";
import {AttributeEditor} from "./AttributeEditor";
import {AttributeRenderer} from "./AttributeRenderer";
import {AddressAttributeEditor} from "./AddressAttributeEditor";
import {AddressAttributeRenderer} from "./AddressAttributeRenderer";
import {ContactRenderer} from "./ContactRenderer";
import {ContactEditor} from "./ContactEditor";

const EntityVerifications = (props) => {
    return (
        <EntityVerificationComponent
        />
    );
};

function TablePaginator() {
    return null;
}

const EntityVerificationComponent = (props) => {


    const breadcrumbItems = [{label: 'Home', href: '/admin/home', isActive: false}, {
        label: 'Match Types',
        href: '',
        isActive: true
    }];
    const dispatch = useDispatch();
    const history = useHistory();
    const pageSize = 15; //TODO- Make this a configurable parameter?
    const [pageNumber, setPageNumber] = useState(0);
    const [lastReload, setLastReload] = useState(Date.now());
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [searchCriteria, setSearchCriteria] = useState();

    useEffect(() => {
        dispatch(getEntityVerificationsCount());
        const pageSelector = {pageNumber: pageNumber, pageSize: pageSize};
        dispatch(getDenormCompanies(pageSelector));
    }, [isReload, dispatch]);

    //Get Redux Values
    const entityVerificationsState = useSelector(state => state.entityVerifications);

    useEffect(() => {
        // When open dialog, we clear state.
        dispatch(clearSelected());
    }, [lastReload, dispatch]);

    useEffect(() => {
        // When open dialog, we clear state.
        if (entityVerificationsState.alertMessage) {
            setAlertMessage(entityVerificationsState.alertMessage);
            setShowAlert(true);
        } else {
            setShowAlert(false);
        }
    }, [entityVerificationsState.alertMessage]);

    const isReload = entityVerificationsState.reload === true;

    const handlePageSubmit = (event) => {
        //event.preventDefault();
        const pageIndex = event - 1;
        try {
            const pageSelector = {pageNumber: pageIndex, pageSize: pageSize};
            setPageNumber(event);
            dispatch(getDenormCompanies(pageSelector));
        }
        catch (err){
            console.log(`Had an exception and err = ${JSON.stringify(err)}`);
        }
    }

    const handlePageChange = (index) => {
        alert(`In EntityVerifications.handlePageChage and index = ${index}`);
    }

    const handleSearchOnKeyDown = (event) => {
        if (event.code === 'Enter') {
            //setLastReload(Date.now())
            dispatch(getDenormByCompanyId(searchCriteria));
        }
    }

    const handleRefresh = (target) => {
        setLastReload(Date.now());
    };

    const getActionItems = () => {
        const actionItems = [];

        actionItems.push(
            {
                label: 'EDIT',
                disabled: true,
                href: '',
                onClickHandler: () => history.push(`/admin/match-types/${entityVerificationsState.selectedId[0]}/edit`),
                isActive: entityVerificationsState.selectedMatchTypes && entityVerificationsState.selectedMatchTypes.length === 1
            }
        );

        return sortBy(actionItems, 'label');
    };


    // Row Data: The data to be displayed.
    const [retrievedRowData, setRowData] = useState(
        entityVerificationsState.entityVerifications
       );

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
            {headerName: "Company Name",
                field: "companyName",
                editable: true,
                pinned: "left",
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'companyName'}, /*, entityVerifiedBooleanColumn: 'COMPANY_PHONE_VERIFIED', entityInvalidatedBooleanColumn: 'COMPANY_PHONE_INVALIDATED'}, */
                cellEditorParams: {attributeType: 'CompanyName', entityIdColumn: 'companyId', attributeDenormPrefix: 'companyName'}
            },
            {headerName: "Company ID", field: "companyId", pinned: "left", width: "100px"
            },
            {headerName: "Company URL",
                field: "companyUrl",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'companyUrl'},
                cellEditorParams: {attributeType: 'CompanyURL', entityIdColumn: 'companyId', attributeDenormPrefix: 'companyUrl'}
            },
            {headerName: "Company Address",
                cellEditor: AddressAttributeEditor,
                cellRenderer: AddressAttributeRenderer,
                cellEditorPopup: true,
                minWidth: 400,
                editable: true,
                cellRendererParams: {attributeDenormPrefix: 'companyAddress'},
                cellEditorParams: {attributeType: 'CompanyAddress', entityIdColumn: 'companyId', attributeDenormPrefix: 'companyAddress'},
            },
            {headerName: "Company Phone Number",
                field: "companyPhone",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'companyPhone'},
                cellEditorParams: {attributeType: 'CompanyPhone', entityIdColumn:'companyId', attributeDenormPrefix: 'companyPhone', verifiedByOptions: 'phone_verified_by', invalidatedReasonOptions: 'phone_invalidated_reasons'},
            },
            {headerName: "Company Email",
                field: "companyEmail",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'companyEmail'},
                cellEditorParams: {attributeType: 'CompanyEmail', entityIdColumn: 'companyId', attributeDenormPrefix: 'companyEmail'},
            },
            {headerName: "Company Primary Trade",
                field: "companyClassPrimaryTrade",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'companyClassPrimaryTrade'},
                cellEditorParams: {attributeType: 'CompanyTradeClassification', entityIdColumn: 'companyId', attributeDenormPrefix: 'companyClassPrimaryTrade'}
            },
            {headerName: "Contact ID", field: "contactId"
            },
            {headerName: "Contact Name", editable: true,
                cellEditorPopup: true,
                cellRenderer: ContactRenderer,
                cellEditor: ContactEditor,
                cellRendererParams: {attributeDenormPrefix: 'contactName'},
                cellEditorParams: {attributeType: 'ContactName', entityIdColumn: 'contactId', attributeDenormPrefix: 'contactName'}
            },
            {headerName: "Contact Title",
                field: "contactTitle",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'contactTitle'},
                cellEditorParams: {attributeType: 'ContactTitle', entityIdColumn: 'contactId', attributeDenormPrefix: 'contactTitle'}
            },
            {
                headerName: "Contact Phone Number",
                field: "contactPhone",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'contactPhone'},
                cellEditorParams: {attributeType: 'ContactPhone', entityIdColumn: 'contactId', attributeDenormPrefix: 'contactPhone', verifiedByOptions: 'phone_verified_by', invalidatedReasonOptions: 'phone_invalidated_reasons'}
            },
            {
                headerName: "Contact Email",
                field: "contactEmail",
                editable: true,
                cellEditorPopup: true,
                cellRenderer: AttributeRenderer,
                cellEditor: AttributeEditor,
                cellRendererParams: {attributeDenormPrefix: 'contactEmail'},
                cellEditorParams: {attributeType: 'ContactEmail', entityIdColumn: 'contactId', attributeDenormPrefix: 'contactEmail'},
            }
        ]
    );

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}></BreadCrumb>
            {entityVerificationsState.loading && (
                <SpinnerOverlay />
            )}
            <Alert
                variant={"danger"}
                show={showAlert}
                onClose={() => setShowAlert(!showAlert)}
                dismissible
                transition={false}
            >
                {alertMessage}
            </Alert>


            <PanelHeader
                header={`Entity Verification Records (${entityVerificationsState.totalCount.toLocaleString()})`}
                toolAction={
                    <div>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="outline-secondary"
                            onClick={handleRefresh}
                        >REFRESH
                        </Button>
                        <DropdownAction items={getActionItems()} />
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            disabled
                            onClick={() => history.push('/admin/match-types/create')}
                        >
                            CREATE
                        </Button>
                    </div>
                }
                searchBar={
                    <FormTextInput
                        placeholder={'Search by company id'}
                        value={searchCriteria}
                        onKeyDown={handleSearchOnKeyDown}
                        onChange={(value) => {
                            setSearchCriteria(value);
                        }}
                        clearable
                        clearableHandle={() => {
                            setSearchCriteria('');
                            setLastReload(Date.now());
                        }}
                    >
                    </FormTextInput>
                }
            />
            <div
                className="ag-theme-quartz"
                style={{ height: '680px' }}
            >
                {entityVerificationsState.entityVerificationsArray.length > 0 ? <AgGridReact
                    // rowData = {retrievedRowData}
                    rowData = {entityVerificationsState.entityVerificationsArray}
                    columnDefs = {colDefs}
                    pagination = {false}
                    groupDisplayType = 'groupRows'
                    enableCellTextSelection="true"
                    ensureDomOrder="true"
                    />: `Loading Table...`}
            </div>
            <TableFooter
                countOfResults={entityVerificationsState.totalCount} // {countOfResults}
                resultsPerPage={pageSize} //{resultsPerPage}
                onSelectPage={handlePageSubmit}
                currentPage={pageNumber}
            />

        </Container>
    );
};

export default EntityVerifications;

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from "react";
import Companies from "./Companies";

export default class CompaniesContainer extends Component {
    static propTypes = {};
    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    render() { // eslint-disable-line
      return (
          <Companies />
      );
    }
}

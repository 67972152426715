/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React from 'react';
import styled from "styled-components";

const TextWrap = (props) => {
    const{
        propertyvalue,
    }=props;
    return(
        <TextSpan>{`${propertyvalue}`}</TextSpan>
    )
}

export default TextWrap;

export const TextSpan = styled.span `
    text-wrap: nowrap;
`;

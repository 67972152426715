/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars

import moment from "moment";

import styled from "styled-components";

import { Line } from "react-chartjs-2";
import { faCheck, faMinus, faPlus, faQuestion } from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Row } from "react-bootstrap";
import { isEmpty } from "lodash";

import Image from "react-bootstrap/Image";

import CompanyIcon from "../../common/assets/img/ToolbeltIcons_Artboard_19.png";
import ContactIcon from "../../common/assets/img/ToolbeltIcons-16.png";

import BreadCrumb from "../../common/components/BreadCrumb";
import PanelHeader from "../../common/components/layout/PanelHeader";
import SpinnerOverlay from "../../common/components/SpinnerOverlay";
import AttributesBox from "./components/AttributesBox";

import Container from "../../common/components/layout/Container";
import Panel from "../../common/components/layout/Panel";
import FormContainer from "../../common/components/layout/FormContainer";

import { APP_COLORS } from "../../common/Constants";
import { getCompanyById } from "./companySlice";

const generateRandom = (min = 0, max = 100) => {
    // find diff
    let difference = max - min;

    // generate random number
    let rand = Math.random();

    // multiply with difference
    rand = Math.floor( rand * difference);

    // add with min value
    rand = rand + min;

    return rand;
}

export const options = {
    responsive: true,
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
}

const formatNumber = (number) => {
    // Use the toLocaleString method to add suffixes to the number
    return number.toLocaleString('en-US', {
        // add suffixes for thousands, millions, and billions
        // the maximum number of decimal places to use
        maximumFractionDigits: 1,
        // specify the abbreviations to use for the suffixes
        notation: 'compact',
        compactDisplay: 'short'
    });
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() => generateRandom(-100, 100)),
            backgroundColor: 'rgb(255, 99, 132)',
        },
        {
            label: 'Dataset 2',
            data: labels.map(() => generateRandom(-100, 100)),
            backgroundColor: 'rgb(75, 192, 192)',
        },
        {
            label: 'Dataset 3',
            data: labels.map(() => generateRandom(-100, 100)),
            backgroundColor: 'rgb(53, 162, 235)',
        },
    ],
}

/**
 * CompanyContainer
 */
const Company = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [company, setCompany] = useState({});
    const [breadcrumbItems, setBreadcrumbItems] = useState([]);

    const companyState = useSelector(state => state.adminCompany);

    useEffect(() => {
        if (props.match.params.id) {
            console.log('Here my id', props.match.params.id)
            dispatch(getCompanyById({id: props.match.params.id}));
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (companyState.company) {
            setCompany(companyState.company);
            setBreadcrumbItems(
                [
                    {label: 'Home', href: '/home', isActive: false},
                    {
                        label: 'Companies',
                        href: '/admin/e/companies',
                        isActive: false
                    },
                    {
                        label: `${companyState?.company['CompanyContractorUniverse.company_id']}`,
                        href: '',
                        isActive: true
                    },
                    {
                        label: 'View Company',
                        href: '',
                        isActive: true
                    }
                ]
            )
        }
    }, [companyState.company]);

    const handleCancel = async () => {
        history.push('/admin/e/companies')
    };

    return (
        <Container>
            <BreadCrumb items={breadcrumbItems}> </BreadCrumb>
            <PanelHeader
                header={`Company - ${company['CompanyContractorUniverse.company_name'] || ''}`}
                subHeader={`View Company - ${company['CompanyContractorUniverse.company_identity'] || ''}`}
            />
            <Panel>
                {(companyState.loading || !company) && (
                    <SpinnerOverlay />
                )}
                <MainContainer>
                    <CompanyDetailHeader company={company} />
                    <CompanyInfo company={company} />
                    <ActivityInfo company={company} />
                    <RelationshipsInfo company={company} />
                    <ServiceInfo company={company} />
                    {/*<ProjectInfo />*/}
                </MainContainer>
                <FormContainer>
                    <Form noValidate>
                        <Form.Group controlId="viewAsJSON">
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} className="buttons">
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        variant="info"
                                        onClick={() => {
                                            handleCancel().then();
                                        }}
                                    >DONE
                                    </Button>
                                    <Button
                                        className="btn-min-width-120 btn-height-35"
                                        style={{marginLeft: "10px"}}
                                        variant="outline-secondary"
                                        onClick={() => {
                                          handleCancel().then();
                                        }}
                                    >CANCEL
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                </FormContainer>
            </Panel>
        </Container>
    );
}

export default Company;

const CompanyDetailHeader = (props) => {
    const {
        company
    } = props;

    return (
        <Row>
            <Col xs={12} md={12} lg={6} xl={6}>
                <BoxContainer>
                    <IconInfo>
                        <Image
                            className="company-icon"
                            src={CompanyIcon}
                            alt="ToolBeltData Company Icon"
                            fluid
                            width={100}
                        />
                        <div style={{paddingTop: 15}}>
                            <CompanyDetailText fontWeight={'bold'}
                                               fontSize={25}>{company['CompanyContractorUniverse.company_name'] || ''}</CompanyDetailText>
                            <CompanyDetailText
                                fontSize={12}>{company['CompanyContractorUniverse.company_url'] || ''}</CompanyDetailText>
                        </div>
                    </IconInfo>

                    <div style={{paddingTop: 10}}>
                        <CompanyDetailText fontSize={14}>
                            {`${company['CompanyContractorUniverse.company_address_delivery_line_1'] || ''} 
                                ${company['CompanyContractorUniverse.company_address_delivery_line_2']  || ''} 
                                ${company['CompanyContractorUniverse.company_address_city']  || ''} 
                                ${company['CompanyContractorUniverse.company_address_region']  || ''} 
                                ${company['CompanyContractorUniverse.company_address_postal_code']  || ''}`}
                        </CompanyDetailText>
                        <CompanyDetailText fontSize={14}>{company['CompanyContractorUniverse.company_phone']  || ''}</CompanyDetailText>
                        <div>
                            <CompanyDetailText fontSize={14}>
                                {company['CompanyContractorUniverse.company_email'] || ''}
                                <span style={{ marginLeft: 10 }}>
                                    {company['CompanyContractorUniverse.company_email_status']
                                        && company['CompanyContractorUniverse.company_email_status'] === "Verified" && (
                                                    <FontAwesomeIcon
                                                        color={APP_COLORS['green']}
                                                        icon={faCheck}
                                                    />
                                                )}
                                    {company['CompanyContractorUniverse.company_email_status']
                                        && company['CompanyContractorUniverse.company_email_status']  !== "Verified" && (
                                        <FontAwesomeIcon
                                            color={APP_COLORS['yellow']}
                                            icon={faQuestion}
                                        />
                                    )}
                                </span>
                            </CompanyDetailText>
                        </div>
                    </div>
                </BoxContainer>
            </Col>
            <Col xs={12} md={12} lg={6} xl={6}>
                <BoxContainer>
                    <Header
                        title={'Contacts'}
                        titleSize={25}
                        seeAll
                        disabled
                    />
                    <ContactItem
                        contactName={`${company['CompanyContractorUniverse.contact_name_given_name'] || ''} ${company['CompanyContractorUniverse.contact_name_middle_name'] || ''} ${company['CompanyContractorUniverse.contact_name_surname'] || ''}`}
                        contactPhone={company['CompanyContractorUniverse.contact_phone']}
                        contactEmail={company['CompanyContractorUniverse.contact_email']}
                        contactTitle={company['CompanyContractorUniverse.contact_title']}
                        contactEmailStatus={company['CompanyContractorUniverse.contact_email_status']}
                    />
                </BoxContainer>
            </Col>
        </Row>
    );
}

const ContactItem = (props) => {
    return (
        <ContactContainer>
            <ContactNameInfo>
                <Image
                    className="contact-icon"
                    src={ContactIcon}
                    alt="ToolBeltData Contact Icon"
                    fluid
                    width={80}
                />
                <div style={{ paddingTop: 10 }}>
                    <CompanyDetailText fontSize={17}>{props.contactName}</CompanyDetailText>
                    <CompanyDetailText fontSize={14}>{props.contactTitle}</CompanyDetailText>
                </div>
            </ContactNameInfo>
            <ContactInfo>
                <CompanyDetailText fontSize={14}>{props.contactPhone}</CompanyDetailText>
                <CompanyDetailText fontSize={14}>
                    {props.contactEmail}
                    <span style={{marginLeft: 10}}>
                        {props.contactEmailStatus && props.contactEmailStatus === "Verified" && (
                            <FontAwesomeIcon
                                color={APP_COLORS['green']}
                                icon={faCheck}
                            />
                        )}
                        {props.contactEmailStatus && props.contactEmailStatus !== "Verified" && (
                            <FontAwesomeIcon
                                color={APP_COLORS['yellow']}
                                icon={faQuestion}
                            />
                        )}
                    </span>
                </CompanyDetailText>
            </ContactInfo>
        </ContactContainer>
    )
}

const CompanyInfo = (props) => {
    const {
        company
    } = props;

    const data = {
        companyIndResidential: true,
        companyIndCommercial: true,
        companyIndIndustrial: true,
        companyIndExterior: true,
        companyIndInterior: true,
        companyIndInsured: true,
        companyProfileCnt: 12,
        companyManufacturerCnt: 10,
        companyAssociationCnt: 10,
        companyLicenseCnt: 3,
        companyPermitCnt: 4,
        companyPolicyCnt: 10,
        companyContactCnt: 5,
        companySourceCnt: 5
    };

    const companyClassTrades = company['CompanyContractorUniverse.company_class_trades'] && company['CompanyContractorUniverse.company_class_trades'].split(',').map((info) => info.trim());
    const companyIndWorkTypesFormatted = company['CompanyContractorUniverse.company_ind_work_types_formatted'] && company['CompanyContractorUniverse.company_ind_work_types_formatted'].split(',').map((info) => info.trim());
    const companyIndWorkLocationsFormatted = company['CompanyContractorUniverse.company_ind_work_locations_formatted'] && company['CompanyContractorUniverse.company_ind_work_locations_formatted'].split(',').map((info) => info.trim());
    const companyIndWorkCredentialsFormatted = company['CompanyContractorUniverse.company_ind_work_credentials_formatted'] && company['CompanyContractorUniverse.company_ind_work_credentials_formatted'].split(',').map((info) => info.trim());

    const typeData = companyClassTrades && companyClassTrades.concat(companyIndWorkTypesFormatted, companyIndWorkLocationsFormatted, companyIndWorkCredentialsFormatted);
    // typeData.push(companyClassTrades);
    // typeData.push(companyIndWorkTypesFormatted);
    // typeData.push(companyIndWorkLocationsFormatted);
    // typeData.push(companyIndWorkCredentialsFormatted);

    let datas = {};

    for (const type in typeData) {
        datas[typeData[type]] = true;
    }

    // console.log(company.companyStatSales);
    // console.log(formatNumber(1200000));
    // console.log(formatNumber(1270000));
    // console.log(Math.round(company.companyStatSales))
    // console.log(Math.ceil(company.companyStatSales / 1000) * 1000);
    //
    // console.log(formatNumber(Math.ceil(company.companyStatSales / 1000) * 1000));
    // console.log(formatNumber(Math.ceil(2768865 / 1000)*1000));

    const companyIdxPropensityIndexLastRolling12Months = company['CompanyContractorUniverse.company_idx_propensity_index_last_rolling_12_months']?.split(',').map((info) => Number(info));
    const currentDate = moment();

    // console.log(moment(currentDate).month());
    // console.log(moment(currentDate).add(-11, 'month').month());

    const dataMonth = [];

    for (let index = 11; index >= 0; index--) {
        let month = moment(currentDate).add(-index, 'month').month();
        dataMonth.push(String(month + 1));
    }

    const chartDatas = {
        // labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        labels: dataMonth,
        datasets: [
            // {
            //     label: "First dataset",
            //     data: [33, 53, 85, 41, 44, 65],
            //     fill: true,
            //     backgroundColor: "rgba(75,192,192,0.2)",
            //     borderColor: "rgba(75,192,192,1)"
            // },
            {
                label: "Second dataset",
                data: companyIdxPropensityIndexLastRolling12Months,
                fill: false,
                borderColor: "#742774"
            }
        ]
    };


    return (
        <>
            <Row>
                <Col xs={12} md={12} lg={6} xl={6}>
                    <BoxChatContainer>
                        <CompanyInfoContainer1>
                            {/*<Image*/}
                            {/*    className="company-icon"*/}
                            {/*    src={CandlestickChart}*/}
                            {/*    alt="ToolBeltData Company Icon"*/}
                            {/*    fluid*/}
                            {/*    style={{ width: 450, height: 100 }}*/}
                            {/*/>*/}
                            <Line
                                data={chartDatas}
                                options={{
                                    legend: {display: false},
                                    maintainAspectRatio: false,
                                    scales: {
                                        yAxes: [{
                                            ticks: {
                                                max: 30,
                                                min: 0,
                                                stepSize: 1
                                            }
                                        }]
                                    },
                                }}
                            />
                        </CompanyInfoContainer1>
                    </BoxChatContainer>
                </Col>
                <Col xs={12} md={12} lg={6} xl={6}>
                    <BoxContainer>
                        <Row>
                            <Col xs={3} md={3} lg={3} xl={3}>
                                <div style={{textAlign: 'center', paddingTop: 15}}>
                                    <CompanyDetailText fontWeight={'bold'} fontSize={18} color={APP_COLORS['green']}>
                                        ${formatNumber(Math.ceil((company['CompanyContractorUniverse.company_acty_spend'] | 0) / 1000) * 1000)}
                                    </CompanyDetailText>
                                    <CompanyDetailText fontSize={14}>Total Spend</CompanyDetailText>
                                </div>
                            </Col>
                            <Col xs={3} md={3} lg={3} xl={3}>
                                <div style={{textAlign: 'center', paddingTop: 15}}>
                                    <CompanyDetailText fontWeight={'bold'} fontSize={18}>
                                        ${formatNumber(Math.ceil((company['CompanyContractorUniverse.company_acty_spend_year_to_date'] | 0) / 1000) * 1000)}
                                    </CompanyDetailText>
                                    <CompanyDetailText fontSize={14}>Spend YTD</CompanyDetailText>
                                </div>
                            </Col>
                            <Col xs={3} md={3} lg={3} xl={3}>
                                <div style={{textAlign: 'center', paddingTop: 15}}>
                                    <CompanyDetailText fontWeight={'bold'} fontSize={18}>{company['CompanyContractorUniverse.company_acty_job_cnt'] | 0}</CompanyDetailText>
                                    <CompanyDetailText fontSize={14}>Total Jobs</CompanyDetailText>
                                </div>
                            </Col>
                            <Col xs={3} md={3} lg={3} xl={3}>
                                <div style={{textAlign: 'center', paddingTop: 15}}>
                                    <CompanyDetailText fontWeight={'bold'} fontSize={18}
                                                       color={APP_COLORS['orange']}>{company['CompanyContractorUniverse.company_acty_active_job_cnt'] | 0}</CompanyDetailText>
                                    <CompanyDetailText fontSize={14}>Active Jobs</CompanyDetailText>
                                </div>
                            </Col>
                        </Row>
                    </BoxContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} lg={12} xl={12}>
                    <BoxContainer>
                        <AttributesBox item={datas} showMore />
                    </BoxContainer>
                </Col>
            </Row>
        </>
    );
}

const ActivityInfo = (props) => {
    const {
        company
    } = props;

    const currentYear = moment().year();

    return (
        <BoxContainer>
            <Header title={'Activity'} seeAll disabled />
            <Row style={{
                paddingBottom: 5,
                marginBottom: 20,
                marginLeft: 5,
                marginRight: 15,
                borderBottom: '2px solid #000',
            }}>
                <Col xs={2}>

                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center', paddingTop: 15}}>
                        <CompanyDetailText fontWeight={'bold'} fontSize={18}>Total</CompanyDetailText>
                        <CompanyDetailText fontSize={18}>{currentYear - 1}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center', paddingTop: 15}}>
                        <CompanyDetailText fontWeight={'bold'} fontSize={18}>Total</CompanyDetailText>
                        <CompanyDetailText fontSize={18}>{currentYear} YTD</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center', paddingTop: 15}}>
                        <CompanyDetailText fontWeight={'bold'} fontSize={18}>Active</CompanyDetailText>
                        <CompanyDetailText fontSize={18}></CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center', paddingTop: 15}}>
                        <CompanyDetailText fontWeight={'bold'} fontSize={18}>Projected</CompanyDetailText>
                        <CompanyDetailText fontSize={18}>in {currentYear}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center', paddingTop: 15}}>
                        <CompanyDetailText fontWeight={'bold'} fontSize={18}>Projected</CompanyDetailText>
                        <CompanyDetailText fontSize={18}>in {currentYear + 1}</CompanyDetailText>
                    </div>
                </Col>
            </Row>
            <Row style={{
                paddingBottom: 5,
                marginBottom: 20,
                marginLeft: 5,
                marginRight: 15,
            }}>
                <Col xs={2}>
                    <CompanyDetailText fontSize={16}>Spend</CompanyDetailText>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>
                            ${formatNumber(Math.ceil((company.companyActySpendLastYear | 0) / 1000) * 1000)}
                        </CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>
                            ${formatNumber(Math.ceil((company.companyActySpendYearToDate | 0) / 1000) * 1000)}
                        </CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>
                            ${formatNumber(Math.ceil((company.companyActyActiveSpend | 0) / 1000) * 1000)}
                        </CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>
                            ${formatNumber(Math.ceil((company.companyActyProjSpendThisYear | 0) / 1000) * 1000)}
                        </CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>
                            ${formatNumber(Math.ceil((company.companyActyProjSpendNextYear | 0) / 1000) * 1000)}
                        </CompanyDetailText>
                    </div>
                </Col>
            </Row>
            <Row style={{
                paddingBottom: 5,
                marginBottom: 20,
                marginLeft: 5,
                marginRight: 15,
            }}>
                <Col xs={2}>
                    <CompanyDetailText fontSize={16}>Jobs</CompanyDetailText>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyActyJobCntLastYear}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyActyJobCntYearToDate}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyActyActiveJobCnt}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyActyProjJobsThisYearCnt}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyActyProjJobsNextYearCnt}</CompanyDetailText>
                    </div>
                </Col>
            </Row>
            <Row style={{
                paddingBottom: 5,
                marginBottom: 20,
                marginLeft: 5,
                marginRight: 15,
            }}>
                <Col xs={2}>
                    <CompanyDetailText fontSize={16}>Permits</CompanyDetailText>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyPermitCntLastYear}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyPermitCntYearToDate}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyPermitActivePermitCnt}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company.companyPermitProjPermitsThisYearCnt}</CompanyDetailText>
                    </div>
                </Col>
                <Col xs={2}>
                    <div style={{textAlign: 'center'}}>
                        <CompanyDetailText fontSize={16}>{company['CompanyContractorUniverse.company_permit_proj_permits_next_year']}</CompanyDetailText>
                    </div>
                </Col>
            </Row>
        </BoxContainer>
    )
}

const RelationshipsInfo = (props) => {
    const {
        company
    } = props;

    const affinities = company['CompanyContractorUniverse.company_affinities'];
    const affiliations = company['CompanyContractorUniverse.company_affiliations'];

    const companyAffinities = affinities && affinities !== '' ? affinities.split(',').map((info) => info.trim()) : [];
    const companyAffiliations = affiliations && affiliations !== '' ? affiliations.split(',').map((info) => info.trim()) : [];

    return (
        <BoxContainer>
            <Header title={'Relationships'} seeAll disabled />
            <Row style={{
                marginTop: 15,
            }}>
                { companyAffinities.length === 0 && companyAffiliations.length === 0 ? (
                    <Col xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            className="btn-min-width-120 btn-height-35"
                            variant="info"
                            disabled
                            onClick={() => {
                                console.log('Add One')
                            }}
                        >
                            ADD ONE
                        </Button>
                    </Col>
                ) : (
                    <>
                        <Col xs={4}>
                            <div style={{ marginBottom: 5 }}>
                                <CompanyDetailText fontWeight={'bold'} fontSize={18}>Brands</CompanyDetailText>
                            </div>
                            {companyAffinities.map((info) => (
                                <div style={{paddingTop: 10}}>
                                    <CompanyDetailText fontSize={15}>{info}</CompanyDetailText>
                                </div>
                            ))}
                        </Col>
                        <Col xs={8}>
                            <div style={{marginBottom: 5}}>
                                <CompanyDetailText fontWeight={'bold'} fontSize={18}>Associations</CompanyDetailText>
                            </div>
                            {companyAffiliations.map((info) => (
                                <div style={{paddingTop: 10}}>
                                    <CompanyDetailText fontSize={15}>{info}</CompanyDetailText>
                                </div>
                            ))}
                        </Col>
                    </>
                )}
            </Row>
        </BoxContainer>
    )
}

const ServiceInfo = (props) => {
    const {
        company
    } = props;

    const data = {
        companyIndResidential: true,
        companyIndCommercial: true,
        companyIndIndustrial: true,
        companyIndExterior: true,
        companyIndInterior: true,
        companyIndLicensed: true,
        companyIndBonded: true,
        companyIndInsured: true,
        companyProfileCnt: 12,
        companyManufacturerCnt: 10,
        companyAssociationCnt: 10,
        companyLicenseCnt: 3,
        companyContactCnt: 5,
        companySourceCnt: 5
    };

    const [more, setMore] = useState(false);

    const services = company['CompanyContractorUniverse.company_services'] || [];
    const profileServices = company['CompanyContractorUniverse.company_profile_services'] || [];

    const companyServices = isEmpty(services) ? [] : services.split(',').map((info) => info.trim());
    const companyProfileServices = isEmpty(profileServices) ? [] : profileServices.split(',').map((info) => info.trim());

    const servicesData = companyServices && companyProfileServices && companyServices.concat(companyProfileServices);

    return (
        <BoxContainer>
            <Header
                title={'Services'}
                showMore={Object.keys(data).length > 6}
                onShowMore={(v) => {
                    console.log(v);
                    setMore(v);
                }}
                more={more}
            />
            { servicesData && (
                <Row style={{
                    marginTop: 15,
                }}>
                    <Col xs={12}>
                        <AttributesBox item={servicesData} showMore={more} />
                    </Col>
                </Row>
            )}
        </BoxContainer>
    )
}

const ProjectInfo = (props) => {
    return (
        <ProjectContainer>
            <div>
                <CompanyDetailText fontWeight={'bold'} fontSize={18}>Projects</CompanyDetailText>
            </div>
            <div></div>
        </ProjectContainer>
    )
}

const Header = (props) => {
    const {
        title,
        titleSize = 20,
        seeAll = false,
        showMore = false,
        onSeeAll,
        onShowMore,
        more = false,
        disabled = false,
    } = props;

    const actionColor = disabled ? '#CCC' : APP_COLORS.primary;

    return (
        <>
            <Row>
                <Col xs={6}>
                    <CompanyDetailText fontWeight={'bold'} fontSize={titleSize}>{title}</CompanyDetailText>
                </Col>
                <Col xs={6} style={{ textAlign: 'right'}}>
                    {(seeAll && !showMore) && (
                        <div
                            style={{ cursor: 'pointer', alignContent: 'center' }}
                            onClick={() => console.log('seeAll')}
                        >
                            <CompanyDetailText
                                fontWeight={'bold'}
                                fontSize={15}
                                color={actionColor}
                            >See All {'>>'}</CompanyDetailText>
                        </div>
                    )}
                    {(showMore && !seeAll) && (
                        <div
                            style={{ cursor: 'pointer', alignContent: 'center' }}
                            onClick={() => {
                                if (more) {
                                    onShowMore(false);
                                } else {
                                    onShowMore(true)
                                }
                            }}
                        >
                            { more ? (
                                <CompanyDetailText
                                    fontWeight={'bold'}
                                    fontSize={15}
                                    color={actionColor}
                                >
                                    Show Less
                                    <FontAwesomeIcon
                                        style={{marginLeft: '10px'}}
                                        color={actionColor}
                                        icon={faMinus}
                                    />
                                </CompanyDetailText>
                            ) : (
                                <CompanyDetailText
                                    fontWeight={'bold'}
                                    fontSize={15}
                                    color={actionColor}
                                >
                                    Show More
                                    <FontAwesomeIcon
                                        style={{marginLeft: '10px'}}
                                        color={actionColor}
                                        icon={faPlus}
                                    />
                                </CompanyDetailText>
                            )}
                        </div>

                    )}
                </Col>
            </Row>
        </>
    )
}

const MainContainer = styled.div`
    padding: 20px;
`;

const BoxContainer = styled.div`
    padding: 20px;
`;

const BoxChatContainer = styled.div`
    padding: 20px;

    //@media (max-width: 990px) {
    //    display: none;
    //}
`;

const DetailHeader = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
`;

const CompanyInfoContainer = styled.div `
    display: grid;
    grid-template-columns: 1fr 400px;
    
    margin-top: 40px;
`;

const CompanyInfoContainer1 = styled.div `
    display: grid;
    grid-template-columns: 500px 150px 150px 150px 150px;
`;

const CompanyInfoContainer2 = styled.div `
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
`;

const ContactContainer = styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
    
    margin-top: 15px;
`;

const ContactNameInfo = styled.div `
    display: grid;
    grid-template-columns: 100px 1fr;
`;

const ContactInfo = styled.div `
    padding-top: 10px;
`;

const DetailContainer = styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
`;

const ActivityContainer = styled.div `
    display: grid;
    grid-template-columns: 1fr 400px;

    margin-top: 20px;
    margin-bottom: 30px;
    padding-right: 60px;
`;

const ActivityContent1 = styled.div `
    display: grid;
    grid-template-columns: 300px 200px 200px 200px 200px;
    padding-bottom: 5px;
    padding-left: 15px;

    margin-bottom: 20px;
    
    border-bottom: 2px solid ${APP_COLORS['black']};
`;

const ActivityContent2 = styled.div `
    display: grid;
    grid-template-columns: 300px 200px 200px 200px 200px;
    padding-bottom: 5px;
    padding-left: 15px;

    margin-bottom: 20px;
`;

const RelationshipsContainer = styled.div `
    display: grid;
    grid-template-columns: 1fr 400px;
    
    margin-top: 30px;
    margin-bottom: 30px;
`;

const RelationshipsContent = styled.div `
    display: grid;
    grid-template-columns: 40% 60%;
    
    padding: 20px;
`

const ServiceContainer = styled.div `
    display: grid;
    grid-template-columns: 1fr 400px;
    
    margin-top: 20px;
`;

const ProjectContainer = styled.div `
    display: grid;
    grid-template-columns: 1fr 400px;
`;

const IconInfo = styled.div `
    display: grid;
    grid-template-columns: 130px 1fr;
`;

const CompanyDetailText = styled.div `
    font-weight: ${props => props.fontWeight ? props.fontWeight : "normal"};
    font-size: ${props => props.fontSize ? props.fontSize : 10}px;
    color: ${props => props.color ? props.color : 'black'};
`;


import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as CubeApi from '../../common/CubeApi';
import {parseCubeFilter, parseOrgConfigFilter} from './CubeFilterBuilder'
import {buildGeoJson, buildPointDetailGeoJson} from "./GeoJsonBuilder";
import * as cache from "../../common/Cache";

const initialState = {
    filters: [],
    companies: [],
    companiesGeo: {
        features: []
    },
    totalCompanies: 0,
    totalContacts: 0,
    currentPage: 1,
    orderBy: {},
    viewMode: 'COMPANY',
    loading: false,
    mapStatus: 'idle',
    productDataType: 'LOC_PLACE', // [LOC_REGION, LOC_COUNTY, LOC_PLACE, LOC_POSTAL_CODE, RECORD]
    accuracyData: null,
    appliedFilters: null
};

/****
 * The default query run to populate counts when Apply Filter is pressed, WHERE clause must emulate the search query
 * RUN TO POPULATE MAP
 */
export const countCompanyAndContactByCriteria = createAsyncThunk('toolbeltdata/tru-pro-company/count-by-criteria', async ({filters, exploreMode}, { getState }) => {
    const viewMode = exploreMode.company.toggleMap ? 'map' : 'list';
    console.log(`[count] querying in mode [${exploreMode.mode}] with view mode [${viewMode}], building query`);
    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    // Set Accuracy Score Default Filter
    const accuracyScore = getState().company.accuracyData;
    const configFilters = parseOrgConfigFilter(filters, account.organization.config, 'Company', accuracyScore);
    const _filters =  configFilters && configFilters.length > 0 ? parseCubeFilter(configFilters) : [];
    // Add Global Default Filters Based on View Mode (Map or List)
    let _filtersWithDefault;
    if (viewMode === 'map') {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.mapViewDefaultFilters);
    } else {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.listViewDefaultFilters);
    }

    const countPayload = {
        measures: ['CompanyContractorUniverse.count', 'CompanyContractorUniverse.count_contacts', 'CompanyContractorUniverse.count_companies'],
        timeDimensions: [],
        filters: _filtersWithDefault,
    };

    console.log(`[count] query built identified [${countPayload.filters.length}] filters, executing`);
    // console.log('countPayload', countPayload);
    const countResult = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        countPayload
    );
    const countCompanies = countResult[0]['CompanyContractorUniverse.count_companies'] || 0;
    const countContacts = countResult[0]['CompanyContractorUniverse.count_contacts'] || 0;

    console.log(`[count] query executed identified [${countCompanies}] companies and [${countContacts}], evaluating`);
    if (countCompanies === 0) {
        return {
            countCompanies,
            countContacts,
            data: null,
            productDataType: 'RECORD',
            filters: filters,
            cubeQuery: _filtersWithDefault,
        };
    }
    const mapClusterMode = countCompanies > config.constants.EXPLORE_MAP_VIEW_CLUSTER_MODE_RECORD_LIMIT ? 'location' : 'record';
    console.log(`[count] query evaluated map cluster mode is [${mapClusterMode}], building query`);

    const filtersForMapResults = _.union(_filtersWithDefault, [
        {
            member: `CompanyContractorUniverse.company_address_loc_city_latitude`,
            operator: 'set',
        },
        {
            member: `CompanyContractorUniverse.company_address_loc_city_longitude`,
            operator: 'set',
        }
    ]);

    if (mapClusterMode === 'location') {
        const payload = {
            measures: ['CompanyContractorUniverse.count_companies', 'CompanyContractorUniverse.count_contacts'],
            dimensions: [
                'CompanyContractorUniverse.company_address_county_name',
                'CompanyContractorUniverse.company_address_loc_city_latitude',
                'CompanyContractorUniverse.company_address_loc_city_longitude',
                'CompanyContractorUniverse.company_address_city',
                'CompanyContractorUniverse.company_address_region'
            ],
            timeDimensions: [],
            filters: filtersForMapResults,
            limit: config.constants.EXPLORE_MAP_VIEW_CLUSTER_MODE_QUERY_LIMIT
        };
        console.log(`[count] query built for cluster mode [${mapClusterMode}] identified [${countPayload.filters.length}] filters, executing`);
        const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
            payload
        );
        console.log(`[count] query executed for cluster mode [${mapClusterMode}], building result set`);
        return {
            countCompanies,
            countContacts,
            data: buildGeoJson(resultSet, 'CompanyContractorUniverse'),
            productDataType: 'LOC_PLACE',
            filters: filters,
            cubeQuery: _filtersWithDefault,
        };
    }

    const payload = {
        measures: ['CompanyContractorUniverse.count_contacts'],
        dimensions: [
            'CompanyContractorUniverse.company_identity',
            'CompanyContractorUniverse.company_name',
            'CompanyContractorUniverse.company_score',
            'CompanyContractorUniverse.company_address_county_name',
            'CompanyContractorUniverse.company_address_latitude',
            'CompanyContractorUniverse.company_address_longitude',
            'CompanyContractorUniverse.company_address_city',
            'CompanyContractorUniverse.company_address_region'
        ],
        timeDimensions: [],
        // For the map results limit to records where there is a lat/long
        filters: filtersForMapResults,
        limit: config.constants.EXPLORE_MAP_VIEW_CLUSTER_MODE_QUERY_LIMIT
    };
    console.log(`[count] query built for cluster mode [${mapClusterMode}] identified [${countPayload.filters.length}] filters, executing`);
    const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    console.log(`[count] query executed for cluster mode [${mapClusterMode}], building result set`);
    return {
        countCompanies,
        countContacts,
        data: buildPointDetailGeoJson(resultSet, 'CompanyContractorUniverse'),
        productDataType: 'RECORD',
        filters: filters,
        cubeQuery: _filtersWithDefault
    };
});

/****
 * The default query run to populate counts when Apply Filter is pressed, WHERE clause must emulate the search query
 * RUN TO POPULATE LIST VIEW
 */
export const searchCompanyByCriteria = createAsyncThunk('toolbeltdata/tru-pro-company/find-by-criteria', async ({filters, exploreMode}, { getState }) => {
    const viewMode = exploreMode.company.toggleMap ? 'map' : 'list';
    console.log(`[search] querying in mode [${exploreMode.mode}] with view mode [${viewMode}], building query`);
    const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
    // Set Accuracy Score Default Filter
    const accuracyScore = getState().company.accuracyData;
    const configFilters = parseOrgConfigFilter(filters, account.organization.config, 'Company', accuracyScore);
    const _filters =  configFilters && configFilters.length > 0 ? parseCubeFilter(configFilters) : [];
    // Add Global Default Filters Based on View Mode (Map or List)
    let _filtersWithDefault;
    if (viewMode === 'map') {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.mapViewDefaultFilters);
    } else {
        _filtersWithDefault = _.union(_filters, config.explore.defaultFilters.listViewDefaultFilters);
    }

    console.log(filters);
    console.log(configFilters)
    console.log(_filtersWithDefault);

    const defaultOrder = {'CompanyContractorUniverse.company_score': 'desc', 'CompanyContractorUniverse.company_class_primary_trade_score': 'desc'};
    const payload = {
        dimensions: [
            'CompanyContractorUniverse.company_id',
            'CompanyContractorUniverse.company_identity',
            'CompanyContractorUniverse.company_score',
            'CompanyContractorUniverse.company_name',
            'CompanyContractorUniverse.company_url',
            'CompanyContractorUniverse.company_url_status',
            'CompanyContractorUniverse.company_address_delivery_line_1',
            'CompanyContractorUniverse.company_address_city',
            'CompanyContractorUniverse.company_address_region',
            'CompanyContractorUniverse.company_address_postal_code',
            'CompanyContractorUniverse.company_phone',
            'CompanyContractorUniverse.company_email',
            'CompanyContractorUniverse.company_email_status',
            'CompanyContractorUniverse.company_class_primary_trade',
            'CompanyContractorUniverse.company_class_primary_trade_score',
            'CompanyContractorUniverse.company_class_trades',
            'CompanyContractorUniverse.company_class_naics_titles_formatted',
            'CompanyContractorUniverse.company_ind_residential',
            'CompanyContractorUniverse.company_ind_commercial',
            'CompanyContractorUniverse.company_ind_industrial',
            'CompanyContractorUniverse.company_ind_exterior',
            'CompanyContractorUniverse.company_ind_interior',
            'CompanyContractorUniverse.company_ind_licensed',
            'CompanyContractorUniverse.company_ind_insured',
            'CompanyContractorUniverse.company_ind_bonded',
            'CompanyContractorUniverse.company_profile_cnt',
            'CompanyContractorUniverse.company_profile_types_mapped',
            'CompanyContractorUniverse.company_profile_reviews_ratings_max',
            'CompanyContractorUniverse.company_profile_manufacturer_profile_cnt',
            'CompanyContractorUniverse.company_profile_association_profile_cnt',
            'CompanyContractorUniverse.company_license_cnt',
            'CompanyContractorUniverse.company_permit_cnt',
            'CompanyContractorUniverse.company_policy_cnt',
            'CompanyContractorUniverse.company_registration_local_registration_status_mapped',
            'CompanyContractorUniverse.company_contact_cnt',
            'CompanyContractorUniverse.company_source_cnt',
            'CompanyContractorUniverse.contact_id',
            'CompanyContractorUniverse.contact_identity',
            'CompanyContractorUniverse.contact_score',
            'CompanyContractorUniverse.contact_name_given_name',
            'CompanyContractorUniverse.contact_name_surname',
            'CompanyContractorUniverse.contact_title',
            'CompanyContractorUniverse.contact_phone',
            'CompanyContractorUniverse.contact_email',
            'CompanyContractorUniverse.contact_email_status',
            'CompanyContractorUniverse.contact_roles',
        ],
        timeDimensions: [],
        ungrouped: true,
        filters: _filtersWithDefault,
        order: defaultOrder,
        limit: config.constants.EXPLORE_LIST_VIEW_LIMIT
    };

    console.log(`[search] query built identified [${payload.filters.length}] filters, executing`);
    const resultSet = await CubeApi.load(config.services.ATLAS.cubeBaseUrl,
        payload
    );
    console.log('[search] query executed');
    return resultSet
});

const companySlice = createSlice({
    name: 'company', initialState,
    reducers: {
        filterUpsert: {
            reducer(state, action) {
                const filterOption = action.payload;
                const index = _.findIndex(state.filters, (ft) => ft.id === filterOption.id);
                if (index >= 0) {
                    state.filters.splice(index, 1, filterOption);
                    return;
                }
                state.filters.push(action.payload);
                state.appliedFilters = null;
            },
            prepare(filterOption) {
                return {
                    payload: {
                        id: filterOption.id,
                        display: filterOption.display,
                        operatorId: filterOption.operatorId,
                        operatorDisplay: filterOption.operatorDisplay,
                        field: filterOption.field,
                        value: filterOption.value,
                        section: filterOption.section,
                        subSection: filterOption.subSection,
                        fieldDisplay: filterOption.fieldDisplay,
                        operand: filterOption.operand,
                        rangeField: filterOption.rangeField,
                        component: filterOption.component,
                    }
                }
            }
        },
        clearAllFilter: {
            reducer(state) {
                state.filters = [];
                state.companies = [];
                state.companiesGeo = {
                    features: []
                };
                state.totalCompanies = 0;
                state.totalContacts =  0;
                state.currentPage = 1;
                state.orderBy = {};
                state.accuracyData = null;
                state.loading = false;
                state.appliedFilters = null;

                return state;
            }
        },
        removeFilter: {
            reducer(state, action) {
                const filterOption = action.payload;
                const index = _.findIndex(state.filters, (ft) => ft.id === filterOption.id);
                if (index >= 0) {
                    state.filters.splice(index, 1);
                }
                state.appliedFilters = null;
            }
        },
        setCurrentPage: {
            reducer(state, action) {
                state.currentPage = action.payload.currentPage;
                state.orderBy = action.payload.orderBy;
            }
        },
        replaceFilter: {
            reducer(state, action) {
                state.filters = action.payload;
                state.appliedFilters = null;
            }
        },
        setAccuracyData: {
            reducer(state, action) {
                state.accuracyData = action.payload;
                state.appliedFilters = null;
            }
        },
    },
    extraReducers: {
        [searchCompanyByCriteria.pending]: (state, action) => {
            state.loading = true;
            state.currentPage = 1;
        },
        [searchCompanyByCriteria.fulfilled]: (state, action) => {
            state.companies = action.payload;
            state.loading = false;
        },
        [searchCompanyByCriteria.rejected]: (state, action) => {
            state.loading = false;
        },
        [countCompanyAndContactByCriteria.pending]: (state, action) => {
            state.loading = true;
            state.mapStatus = 'loading';
            state.appliedFilters = null;
        },
        [countCompanyAndContactByCriteria.fulfilled]: (state, action) => {
            state.totalCompanies = action.payload.countCompanies;
            state.totalContacts = action.payload.countContacts;
            state.companiesGeo = action.payload.data;
            state.productDataType = action.payload.productDataType;
            state.loading = false;
            state.mapStatus = 'succeed';
            state.appliedFilters = {
                filters: action.payload.filters,
                totalCompanies: action.payload.countCompanies,
                totalContacts: action.payload.countContacts,
                cubeQuery: action.payload.cubeQuery,
            };
        },
        [countCompanyAndContactByCriteria.rejected]: (state, action) => {
            state.loading = false;
            state.mapStatus = 'error';
            state.appliedFilters = null;
        },
    }
});

export const {replaceFilter, clearAllFilter, filterUpsert, removeFilter, setCurrentPage, setAccuracyData} = companySlice.actions;
export default companySlice.reducer;

/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, { Component } from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import CompaniesContainer from "./CompaniesContainer";
import Company from "./Company";
import CompanyJsonView from "./CompanyJsonView";

export default class CompaniesRouter extends Component {
  static propTypes = {

  };

  static defaultProps = {

  };

  render() { // eslint-disable-line
    return (
      <Switch>
        <Route path="/admin/e/companies/:id" exact component={Company} />
        <Route path="/admin/e/companies/:id/view-as-json" component={CompanyJsonView}/>
        <Route path="/admin/e/companies" component={CompaniesContainer} />
      </Switch>
    );
  }
}

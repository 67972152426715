import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import _ from 'lodash';
import config from '../../config'
import * as Api from "../../common/Api";
import {Message} from "react-chat-ui";

const initialState = {
    replying: false,
    reload: false,
    isTyping: false,
    currentConversationId: '',
    currentConversationName: '',
    alertMessage: undefined,
    successMessage: undefined,
    messages: [],
    conversations: []
};

export const getChatMessages = createAsyncThunk('chatMessage/get-chat-messages', async (payload, { getState, rejectWithValue }) => {
    try {
        const conversationId = payload;
        let messages = [];
        if(conversationId !== undefined) {
            const GET_URL = `${config.services.ATLAS.apiUrl}/chat-api/conversation/${conversationId}`;
            messages = await Api.get(true, GET_URL);
        }
        return messages;
    }
    catch (err){
        console.log(`Error in getChatMessages and err.response.body.message = ${err.response.body.message}`);
        return rejectWithValue(err.response.body.message);
    }
});


export const selectConversation = createAsyncThunk('chatMessage/selectConversation', async (payload, { getState, rejectWithValue }) => {
    const state = getState()['chatMessages'];
    const conversation = state.conversations.find(conversation => conversation.id === payload);
    return conversation;
});


export const getConversations = createAsyncThunk('chatMessage/get-conversations', async (payload, { getState, rejectWithValue }) => {
    try {
        const GET_URL = `${config.services.ATLAS.apiUrl}/conversation`;
        const messageResponse = await Api.get(true, GET_URL);
        return messageResponse;
    }
    catch (err){
        console.log(`Error in getChatMessages and err.response.body.message = ${err.response.body.message}`);
        return rejectWithValue(err.response.body.message);
    }
});

export const submitChatMessage = createAsyncThunk('chatMessage/submit-chat-message', async (message, {getState, rejectWithValue}) => {

    try {
            const POST_URL = `${config.services.ATLAS.apiUrl}/chat-api`;
            const state = getState()['chatMessages'];

            const messageResponse = await Api.post(true, POST_URL, {options: {},
                data: {conversationId: state.currentConversationId,
                    message:message}},);
            return messageResponse;
       // }
    }
    catch (err){
        console.log(`Error in submitChatMessage and err.response.body.message = ${err.response.body.message}`);
        return rejectWithValue(err.response.body.message);
    }
});


const chatMessagesSlice = createSlice({
    name: 'chatMessagesSlice', initialState,
    reducers: {
        clearSelected: {
            reducer(state, action) {
                state.selectedId = [];
                state.selectedMatchTypes = [];
            }
        },
    },
    extraReducers: {
        [getConversations.pending]: (state, action) => {
        },
        [getConversations.fulfilled]: (state, action) => {

            state.conversations = action.payload;
        },
        [getConversations.rejected]: (state, action) => {
            state.alertMessage = action.payload;
        },
        [selectConversation.pending]: (state, action) => {
        },
        [selectConversation.fulfilled]: (state, action) => {

            state.currentConversationId = action.payload.id;
            state.currentConversationName = action.payload.name;
        },
        [selectConversation.rejected]: (state, action) => {
            state.alertMessage = action.payload;
        },

        [getChatMessages.pending]: (state, action) => {
        },
        [getChatMessages.fulfilled]: (state, action) => {
           if(action.payload) {
              state.messages = action.payload.messages;
           }
        },
        [getChatMessages.rejected]: (state, action) => {
            state.alertMessage = action.payload;
        },
        [submitChatMessage.pending]: (state, action) => {
            state.isTyping = true;
            state.messages = [...state.messages, new Message({id:0, message: action.meta.arg})];
        },
        [submitChatMessage.fulfilled]: (state, action) => {
            state.isTyping = false;
            state.messages = [...state.messages, ...action.payload.messages];
            state.currentConversationId = action.payload.conversationId;
            if(action.payload.conversationId !== ''){
                state.currentConversationName = action.payload.conversationName;
            }


        },
        [submitChatMessage.rejected]: (state, action) => {
            state.alertMessage = action.payload;
        },
    }
});


export const {
    clearSelected
} = chatMessagesSlice.actions;
export default chatMessagesSlice.reducer;

/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Image} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import homeScreenImg from '../../common/assets/img/ToolBelt_home_screen.png'
import {ChatFeed, ChatInput, Message} from 'react-chat-ui'
import {getChatMessages, getConversations, selectConversation, submitChatMessage} from "./chatMessagesSlice";
import {useDispatch, useSelector} from "react-redux";
import {
    getDenormCompanies,
    getEntityVerificationsCount
} from "../../admin/entity-verifications/entityVerificationsSlice";
import * as cache from "../../common/Cache";
import config from "../../config";
import _ from "lodash";



export default function Home() {
    const history = useHistory();
    const dispatch = useDispatch();

    //Get Redux Values
    const chatMessagesState = useSelector(state => state.chatMessages);

    const accountState = useSelector(state=>state.account);

    const [displayMode, setDisplayMode] = useState('explore');

    useEffect(()=>{
        const account = cache.get(config.constants.CACHE_KEY_ACCOUNT);
        const restrictedFeatures = _.get(account.organization, 'config.features');
        const featureChat = restrictedFeatures.find(service => service === 'chat');
        if(featureChat){
            setDisplayMode('chat');
        }
    });

    useEffect(() => {
        dispatch(getConversations());
    }, [dispatch]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const inputValue = event.target[0].value;
        await dispatch(submitChatMessage(inputValue));
        dispatch(getConversations());
    };

    const handleConversationSelect = async (event) =>   {
        // alert(`selecting a conversation! ID: ${event}`);
        let conversationResponse = await dispatch( selectConversation(event));
        const conversationId = conversationResponse.payload.id;
        dispatch(getChatMessages(conversationResponse.payload.id));
    };

    if(displayMode === 'chat') {
        return (
            <div style={{display: 'flex', gap: '20px'}}>
                <div style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    flex: '19%',
                    height: '500px',
                    overflowY: 'auto',
                }}>
                    <b>Previous Chats</b>
                    {chatMessagesState.conversations.map((conversation, index) => (
                        <p>
                            <div
                                onClick={() => handleConversationSelect(conversation.id)}>{JSON.stringify(conversation.name)}</div>
                        </p>
                    ))}
                </div>
                <div style={{backgroundColor: 'white', padding: '20px', flex: '79%'}}>
                    <p className="display-5 home font-weight-bold">Current Conversation
                        Name: {chatMessagesState.currentConversationName} ({chatMessagesState.currentConversationId})</p>


                    <ChatFeed
                        messages={chatMessagesState.messages} // Array: list of message objects
                        isTyping={chatMessagesState.isTyping} // Boolean: is the recipient typing
                        hasInputField={false} // Boolean: use our input, or use your own
                        showSenderName // show the name of the user who sent the message
                        bubblesCentered={true} //Boolean should the bubbles be centered in the feed?
                        // JSON: Custom bubble styles
                        bubbleStyles={
                            {
                                text: {
                                    fontSize: 15
                                },
                                chatbubble: {
                                    borderRadius: 20,
                                    padding: 10
                                }
                            }
                        }
                    />

                    <form onSubmit={handleSubmit}>
                        <input type='text' style={{width: '500px'}}></input>
                        <button>Enter</button>
                    </form>
                </div>
            </div>
        );
    }
    else {
        return (

            <Row noGutters>
                <Col xl={12} lg={12} md={12} sm={12} className="text-center text-muted">
                    <Image
                        src={homeScreenImg}
                        rounded={true}
                        width={'400px'}
                    /><br/>
                    <p className="display-5 home font-weight-bold">Welcome, nice to see you!</p>
                    <p className="display-5 home font-weight-light">You've made a great choice. Now let's get going.</p>
                    <p><Button
                        data-testid="homeToExploreBtn"
                        className="btn-min-width-120 btn-height-35"
                        variant="info"
                        onClick={() => history.push('/org/explore')}
                    >
                        START EXPLORING
                    </Button></p>
                </Col>
            </Row>
        );
    }
}

/**
 * Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

const AdminMenu = [
    {
        name: 'HOME',
        path: '/admin/home',
    },
    {
        name: 'SOURCES',
        path: '/admin/sources',
    },
    {
        name: 'INPUTS',
        path: '/admin/inputs',
    },
    {
        name: 'MANAGE',
        path: '/admin/manage',
        megaMenu: {
            administer: [
             {
                 name: 'Accounts',
                 path: '/admin/accounts'
             },
             {
                 name: 'Integrations',
                 path: '/admin/integrations'
             },
             {
                 name: 'Lists',
                 path: '/admin/lists'
             },
             {
                 name: '- Errors',
                 path: '/admin/list-errors'
             },
             {
                 name: '- Matches',
                 path: '/admin/list-matches'
             },
             {
                 name: '- Match Logs',
                 path: '/admin/list-match-logs'
             },
             {
                 name: 'Organizations',
                 path: '/admin/organizations'
             }
            ],
            entities: [
                {
                    name: 'Companies',
                    path: '/admin/e/companies'
                },
            ],
            configure: [
                {
                    name: 'Match Templates',
                    path: '/admin/match-templates'
                },
                {
                    name: 'Match Types',
                    path: '/admin/match-types'
                }
            ],
            tools: [
                {
                    name: 'Contractor Directory',
                    path: '/admin/tools/contractor-directory'
                },
                {
                    name: 'Entity Verification',
                    path: '/admin/entity-verifications'
                }
            ],
        },
    }
];

export default AdminMenu;

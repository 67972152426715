/**
 * @copyright Copyright ToolBelt Data Inc., 2020 All Rights Reserved
 */

import cubejs from '@cubejs-client/core';

import config from '../config';
import {supportsLocalStorage} from './Utils';
import * as cache from './Cache';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

// eslint-disable-next-line
export async function load(endpoint, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        // TODO: add some utils for encoding / decoding tokens
        const _apiKey = cache.get(config.constants.CACHE_KEY_API);
        const _jwt = cache.get(config.constants.CACHE_KEY_JWT);
        if (_apiKey) {
            try {
                const cubejsApi = cubejs(_apiKey ? _apiKey.key : null,
                    {
                        apiUrl: endpoint,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'x-api-key': `${_apiKey ? _apiKey.key : ''}`,
                            'x-api-jwt': _jwt
                        }
                    });

                let continueWait = true;
                while (continueWait === true) {
                    const resultSet = await cubejsApi.load(query);
                    if (!resultSet) {
                        // console.error(res.body.error);
                        throw new Error('Error Fetching data from CubeApi');
                    }
                    const responses = resultSet.loadResponses;
                    if (responses && responses[0] && responses[0].data) {
                        return responses[0].data;
                    } else if (responses[0].error && responses[0].error === 'Continue wait'){
                        await delay(3000);
                        continueWait = true;
                    } else {
                        continueWait = false;
                    }
                }
                throw new Error('Error Fetching data from CubeApi');
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    // TODO: is there a need to defer?
                    // dispatch(logout());
                }
                // console.error(err);
                throw err;
            }
        } else {
            throw new Error('Unable to make request, Authentication Required');
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}

export async function meta(endpoint, query = {}, logoutOn401 = true) {
    if (supportsLocalStorage()) {
        // TODO: add some utils for encoding / decoding tokens
        const _apiKey = cache.get(config.constants.CACHE_KEY_API);
        const _jwt = cache.get(config.constants.CACHE_KEY_JWT);
        if (_apiKey) {
            try {
                const cubejsApi = cubejs(_apiKey ? _apiKey.key : null,
                    {
                        apiUrl: endpoint,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'x-api-key': `${_apiKey ? _apiKey.key : ''}`,
                            'x-api-jwt': _jwt
                        }
                    });

                let continueWait = true;
                while (continueWait === true) {
                    const cubeMeta = await cubejsApi.meta();
                    if (!cubeMeta) {
                        // console.error(res.body.error);
                        throw new Error('Error Fetching data from CubeApi');
                    }
                    if (cubeMeta) {
                        return cubeMeta;
                    } else {
                        continueWait = false;
                    }
                }
                throw new Error('Error Fetching data from CubeApi');
            } catch (err) {
                if (err && err.status === 401 && logoutOn401) {
                    // TODO: is there a need to defer?
                    // dispatch(logout());
                }
                // console.error(err);
                throw err;
            }
        } else {
            throw new Error('Unable to make request, Authentication Required');
        }
    } else {
        throw new Error('Unhandled Exception, Browser not Supported');
    }
}
